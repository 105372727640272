import React, { useEffect, useState } from 'react';
import {
  ChartTitle,
  Container,
  ModalPopup,
  ModalTitle,
  Title,
} from '../../styled/components/Popups/PotentialCustomersPopup';
import { useDispatch, useSelector } from 'react-redux';
import { setDraw, setOverlay } from '../../store/maps';
import {
  setTotalCustomersChart,
} from '../../store/charts';
import { addDataCustomersChart } from '../../helpers/charts/updateChart';
import { createTotalCustomersChart } from '../../helpers/charts/totalCustomersChart';
import { regex } from '../../helpers/regexs/regex';
import { TotalCustomersChart } from '../../styled/components/Charts/chart';
import { getGroupsByCodeAsync } from '../../store/groups';
import { getPolygon } from '../../helpers/features/getPolygon';
import { setVisibleToSaveAreaPopup } from "../../store/profile";
import { useLocation } from 'react-router-dom';
export const PotentialCustomersPopup = () => {
  const totalCustomersChart = useSelector(
    state => state.charts.totalCustomersChart);
  const groupsByPolygon = useSelector(state => state.groups.groupsByPolygon);
  const profile = useSelector(state => state.profile.profile);
  const drawArea = useSelector(state => state.maps.draw);
  const overlays = useSelector(state => state.maps.overlays);
  const map = useSelector(state => state.maps.map);
  const source = useSelector(state => state.maps.source);
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState = location.state?.state || {};

  useEffect(() => {
    if (!totalCustomersChart || !groupsByPolygon.length)
      return null;

    addDataCustomersChart(totalCustomersChart, groupsByPolygon);
  }, [groupsByPolygon, totalCustomersChart]);

  useEffect(() => {
    dispatch(setTotalCustomersChart(createTotalCustomersChart()));
  }, [dispatch]);

  const handleDeleteDrawArea = () => {
    const features = source.getFeatures();

    features.forEach(feature => {
      if (feature.getGeometry().getType() !== 'Point') {
        source.removeFeature(feature);
      }
    });

    dispatch(setDraw(null));
    map.removeInteraction(drawArea);
  };

  const handleSelectOverlay = () => {
    dispatch(setOverlay('Geodemographics'));
    map.getLayers().getArray().forEach(layer => layer.get('name') ===
      'Geodemographics' ? layer.setVisible(true) : null);

    const polygon = getPolygon(map);
    if (polygon)
      dispatch(getGroupsByCodeAsync(polygon));
  };

  const totalCustomers = groupsByPolygon.length ? regex.numberPeople(
    String(groupsByPolygon.map(({ number_clients }) => Number(number_clients))
      .reduce((acc, val) => acc + val))) : null;

  const showToSaveAreaPopup = () => {
    dispatch(setVisibleToSaveAreaPopup(true))
  }
  useEffect(() => {
    const { areaRecord } = locationState || {};
    if (areaRecord && areaRecord?.id) {
      if (map && source) {
        setTimeout(() => {
          handleSelectOverlay();
          }, 5000);
      }else{
        //  console.error(" Map or source is not available yet.");
      }
    }
  }, [locationState, map, source]);
  return (
    <Container visible={drawArea}>
      <Title>Map Selection
        <i onClick={handleDeleteDrawArea} className="fas fa-times" /></Title>
      <ModalPopup visible={!overlays.filter(
        overlay => overlay === 'Geodemographics').length}>
        <ModalTitle onClick={handleSelectOverlay}>
          Select the OAC Demographics - Groups overlays
        </ModalTitle>
      </ModalPopup>
      <ChartTitle>Total Potential Customers: {totalCustomers}
        {(profile.plan === 'ultimate' && totalCustomers) && <i className="fas fa-save"
          onClick={showToSaveAreaPopup}
          style={{ cursor: 'pointer', marginLeft: '10px' }} />}
      </ChartTitle>
      <TotalCustomersChart id="total-customers-chart" width="200"
        height="150" />
    </Container>
  );
};