import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {setSelectionGeodemographicChart} from '../../store/charts';
import {
  addDataSelectionChart,
} from '../../helpers/charts/updateChart';
import {useHistory} from 'react-router-dom';
import {createGeoPieChart} from '../../helpers/charts/geoPieChart';
import {setOverlay} from '../../store/maps';
import {
  SelectionChartModal,
  ChartContainer,
  ChartWrapper,
  ChartTitle,
  SelectionModalTitle,
} from '../../styled/components/Charts/chart';
import {MapSelectionBtn} from '../Buttons/MapSelectionBtn';
import {getPolygon} from '../../helpers/features/getPolygon';
import {getGroupsByCodeAsync} from '../../store/groups';

export const SelectionGeoChart = () => {
  const selectionGeoChart = useSelector(
      state => state.charts.selectionGeoChart);
  const groupsByPolygon = useSelector(state => state.groups.groupsByPolygon);
  const drawArea = useSelector(state => state.maps.draw);
  const map = useSelector(state => state.maps.map);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectionGeoChart || !groupsByPolygon.length)
      return null;

    addDataSelectionChart(selectionGeoChart, groupsByPolygon);
  }, [selectionGeoChart, groupsByPolygon]);

  useEffect(() => {
    if (selectionGeoChart)
      return null;

    const selectionChart = createGeoPieChart('selection-geodemographic-chart',
        history);

    dispatch(setSelectionGeodemographicChart(selectionChart));
  }, [dispatch, selectionGeoChart, history]);

  const handleChangeVisibleLayer = layer => layer.get('name') ===
  'Geodemographics' ? layer.setVisible(true) : null;

  const handleClickTitle = () => {
    dispatch(setOverlay('Geodemographics'));
    map.getLayers().getArray().forEach(handleChangeVisibleLayer);
    history.push('/map');

    const polygon = getPolygon(map);

    if (polygon)
      dispatch(getGroupsByCodeAsync(polygon));
  };

  return (
      <ChartContainer>
        <SelectionChartModal visible={!groupsByPolygon.length || !drawArea}>
          <MapSelectionBtn>
            <SelectionModalTitle onClick={handleClickTitle}>
              Select an area on the map
            </SelectionModalTitle>
          </MapSelectionBtn>
        </SelectionChartModal>
        <ChartTitle>
          Selection Geodemographic breakdown
        </ChartTitle>
        <ChartWrapper>
          <canvas id="selection-geodemographic-chart"/>
        </ChartWrapper>
      </ChartContainer>
  );
};