import {
  CollapsedMenu, CollapsedMenuWrapper,
  MenuIcon, Navigation,
  NavItem, NavList,
} from '../../styled/components/Pages/Home';
import { UploadsDropdown } from '../Dropdowns/UploadsDropdown';
import { ClientsCreateForm } from '../Forms/ClientsCreateForm';
import { SettingTwoFactorAuthForm } from '../Forms/SettingTwoFactorAuthForm';
import { ClientsNotifications } from '../Notifications/ClientsNotifications';
import { HelpPopup } from '../Popups/HelpPopup';
import { CollapsedUploadsDropdown } from '../Dropdowns/CollapsedUploadsDropdown';
import { usePendingPayment } from "../../hooks/usePendingPayment";
import { UploadDropdownWrapper } from "../../styled/components/Dropdowns/UploadDropdown";
import { useDispatch, useSelector } from "react-redux";
import { setVisibleToPayPopup } from "../../store/profile";
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MySavedArea } from '../Popups/MySavedArea';
export const HomeNavigation = ({ onLogout, onCreateClients }) => {
  const pendingPayment = usePendingPayment()
  const dispatch = useDispatch();
  const { page } = useParams();
  const [isTwoFactorOpen, setIsTwoFactorOpen] = useState(false);
  const profile = useSelector(state => state.profile.profile);
  const showToPayPopup = () => {
    dispatch(setVisibleToPayPopup(true))
  }
  useEffect(() => {
    if (page === 'setting') {
      setIsTwoFactorOpen(true);
    } else {
      setIsTwoFactorOpen(false);
    }
  }, [page]);

  const menu = (
    <CollapsedMenu>
      <CollapsedMenu.Item>
        <SettingTwoFactorAuthForm
          btnText={'Setting'}
          autoOpen={isTwoFactorOpen}
        />
      </CollapsedMenu.Item>
      <CollapsedMenu.Item onClick={showToPayPopup}>
        <ClientsNotifications />
      </CollapsedMenu.Item>
      <CollapsedMenu.Item>
        <ClientsCreateForm
          btnText={'Upload'}
          onCreateClients={onCreateClients} />
      </CollapsedMenu.Item>
      {pendingPayment
        ?
        <CollapsedMenu.Item
          onClick={showToPayPopup}
        >
          <div>
            My uploads<DownOutlined />
          </div>
        </CollapsedMenu.Item>
        :
        <CollapsedMenu.Item>
          <CollapsedUploadsDropdown />
        </CollapsedMenu.Item>
      }
      <CollapsedMenu.Item>
        <HelpPopup
          btnText={'Help'}
        />
      </CollapsedMenu.Item>
      <CollapsedMenu.Item>
        <div onClick={onLogout}>Logout</div>
      </CollapsedMenu.Item>
    </CollapsedMenu>
  );

  return (
    <Navigation>
      <CollapsedMenuWrapper overlay={menu} placement="bottomRight">
        <MenuIcon />
      </CollapsedMenuWrapper>
      <NavList>
        <NavItem style={{ display: 'none' }}>
          <SettingTwoFactorAuthForm
            btnText={'Setting'}
            autoOpen={isTwoFactorOpen}
          />
        </NavItem>
        <NavItem>
          <ClientsNotifications />
        </NavItem>
        <NavItem>
          <ClientsCreateForm
            btnText={'Upload'}
            onCreateClients={onCreateClients}
          />
        </NavItem>
        {pendingPayment
          ?
          <NavItem>
            <UploadDropdownWrapper
              onClick={showToPayPopup}
            >
              My Uploads
            </UploadDropdownWrapper>
          </NavItem>
          :
          <NavItem>
            <UploadsDropdown />
          </NavItem>
        }
        {profile.plan === 'ultimate' && (
          <NavItem>
            <MySavedArea
              btnText={'My Saved Areas'}
            />
          </NavItem>
        )}

        <NavItem>
          <HelpPopup
            btnText={'Help'}
          />
        </NavItem>
        <NavItem>
          <div onClick={onLogout}>Logout</div>
        </NavItem>
      </NavList>
    </Navigation>
  );
};
