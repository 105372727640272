import {
  BtnControl,
  IconControl,
} from '../../styled/components/Controls/MapControl';
import {closePopup} from '../../helpers/popup/closePopup';
import {setVisibleToPayPopup} from "../../store/profile";
import {usePendingPayment} from "../../hooks/usePendingPayment";
import {useDispatch} from "react-redux";

export const PanMapBtn = () => {
  const pendingPayment = usePendingPayment()
  const dispatch = useDispatch();

  return (
    <>
      {pendingPayment
        ?
        <BtnControl
          onClick={() => dispatch(setVisibleToPayPopup(true))}
          title='Pan the map'
        >
          <IconControl className="fas fa-mouse-pointer"/>
        </BtnControl>
        :
        <BtnControl
          title='Pan the map'
          onClick={closePopup}
        >
          <IconControl className="fas fa-mouse-pointer"/>
        </BtnControl>
      }
    </>
  );
};
