import {
  Container,
  TextPopup,
  CloseIcon, CoordinateText,
} from '../../styled/components/Popups/GeocoderPopup';
import {useDispatch, useSelector} from 'react-redux';
import {setGeocoderPopup} from '../../store/geocoder';

export const GeocoderCard = () => {
  const geocoderPopup = useSelector(state => state.geocoder.geocoderPopup);
  const geocoder = useSelector(state => state.geocoder.geocoder);
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    const source = geocoder.getSource();

    geocoderPopup.overlay.setPosition(undefined);
    dispatch(setGeocoderPopup({
      visible: false,
    }));
    source.getFeatures().forEach(feature => {
      source.removeFeature(feature);
    });
  };

  if (!geocoderPopup.visible)
    return null;

  return (
      <Container>
        <div>
          <TextPopup>
            {geocoderPopup.address}
          </TextPopup>
        </div>
        <CloseIcon onClick={handleClosePopup}
                   className="fas fa-times popup__btn"/>
      </Container>
  );
};