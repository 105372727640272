export const imageAnimation = {
    initial: {x: 0, backgroundColor: '#5ba0f1'},
    inView: {
        x: '100%', backgroundColor: 'none',
        transition: {
            ease: "cubic-bezier(0.215, 0.61, 0.355, 1)",
            duration: 0.4,
            delay: 0.4
        }
    },
}

export const scalingAnimation = {
    initial: {scaleX: 0, opacity: 0},
    inView: {scaleX: 1, opacity: 1}
}

export const textFadeLeftAnimation = {
    initial: {x: '-100%', opacity: 0},
    inView: {x: 0, opacity: 1}
}

export const textFadeRightAnimation = {
    initial: {x: '100%', opacity: 0},
    inView: {x: 0, opacity: 1}
}

export const textFadeTopAnimation = {
    initial: {y: '100%', opacity: 0},
    inView: {y: 0, opacity: 1}
}

export const textFadeTopAnimation2 = {
    initial: {y: '40%', opacity: 0},
    inView: {y: 0, opacity: 1}
}

export const transitionEffect = {
    transition: {ease: "cubic-bezier(0.215, 0.61, 0.355, 1)", duration: 0.7, delay: 0.5}
}
