import Chart from 'chart.js/auto';
import {
  bronzeColor,
  goldColor,
  platinumColor, silverColor,
} from '../../styled/components/Variables/variables';

export const createRadarChart = (categories) => {
  const ctx = document.getElementById('customer-value-chart')
      .getContext('2d');

  // Chart.plugins.register({
  //   afterDatasetsDraw: function(chartInstance, easing) {
  //     // To only draw at the end of animation, check for easing === 1
  //     var ctx = chartInstance.chart.ctx;
  //     chartInstance.data.datasets.forEach(function(dataset, i) {
  //       var meta = chartInstance.getDatasetMeta(i);
  //       if (!meta.hidden) {
  //         meta.data.forEach(function(element, index) {
  //           // Draw the text in black, with the specified font
  //           ctx.fillStyle = 'grey';
  //           var fontSize = 16;
  //           var fontStyle = 'normal';
  //           var fontFamily = 'Helvetica Neue';
  //           ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
  //           // Just naively convert to string for now
  //           var dataString = dataset.data[index].toString();
  //           // Make sure alignment settings are correct
  //           ctx.textAlign = 'center';
  //           ctx.textBaseline = 'middle';
  //           var padding = 5;
  //           var position = element.tooltipPosition();
  //           ctx.fillText(dataString + '%', position.x, position.y - (fontSize / 2) - padding);
  //         });
  //       }
  //     });
  //   }})

  return new Chart(ctx, {
    type: 'radar',
    data: {
      labels: categories.filter((category, index) => index < 6)
          .map(({name}) => name),
      datasets: [
        {
          label: 'Platinum',
          data: Array(6).fill(1).map(() => Math.random() * 500),
          fill: true,
          hidden: false,
          backgroundColor: 'rgba(206,206,206,0.8)',
          borderWidth: 1,
          borderColor: platinumColor,
          pointBackgroundColor: platinumColor,
          pointBorderColor: platinumColor,
          pointHoverBackgroundColor: platinumColor,
          pointHoverBorderColor: platinumColor,
        },
        {
          label: 'Gold',
          data: Array(6).fill(1).map(() => Math.random() * 500),
          fill: true,
          hidden: false,
          backgroundColor: 'rgba(255, 213, 46, 0.4)',
          borderWidth: 1,
          borderColor: goldColor,
          pointBackgroundColor: goldColor,
          pointBorderColor: goldColor,
          pointHoverBackgroundColor: goldColor,
          pointHoverBorderColor: goldColor,
        },
        {
          label: 'Silver',
          data: Array(6).fill(1).map(() => Math.random() * 500),
          fill: true,
          hidden: false,
          backgroundColor: 'rgba(149,140,140, 0.4)',
          borderWidth: 1,
          borderColor: silverColor,
          pointBackgroundColor: silverColor,
          pointBorderColor: silverColor,
          pointHoverBackgroundColor: silverColor,
          pointHoverBorderColor: 'rgb(149,140,140,0,1)',
        },
        {
          label: 'Bronze',
          data: Array(6).fill(1).map(() => Math.random() * 500),
          fill: true,
          hidden: false,
          backgroundColor: 'rgba(255,150,80, 0.4)',
          borderWidth: 1,
          borderColor: bronzeColor,
          pointBackgroundColor: bronzeColor,
          fontColor: bronzeColor,
          pointBorderColor: bronzeColor,
          pointHoverBackgroundColor: bronzeColor,
          pointHoverBorderColor: bronzeColor,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      scales: {
        r: {
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          position: 'right',
          onHover: (event,chart,legend) => {
            event.native.target.style.cursor = 'pointer';
          },
          onLeave: (event) => {
            event.native.target.style.cursor = 'default';
          },
          labels: {
            boxWidth: 0,
            padding: 10,
            font: {
              size: 18,
            },
            generateLabels: (chart) => {
              const data = chart.data;
              return data.datasets.map((data, i) => {

                return {
                  text: data.label,
                  fontColor: data.borderColor,
                  hidden: false,
                  datasetIndex: i,
                };
              });
            },
          },
          onClick(e, legendItem, legend) {
            const index = legendItem.datasetIndex;
            const ci = legend.chart;

            if (ci.isDatasetVisible(index)) {
              ci.hide(index, 2);
              legendItem.hidden = true;
            } else {
              legendItem.hidden = false;
            }
            ci.data.datasets = ci.data.datasets.map((label, i) => {
              if (i === index) {
                return {...label, hidden: legendItem.hidden};
              }

              return label;
            });

            ci.update();

            ci.legend.legendItems = ci.legend.legendItems.map((legend, i) => {
              return {...legend, hidden: ci.data.datasets[i].hidden};
            });
          },
        },
        tooltip: {
          display: false,
        },
      },
    },
  });
};