import {store} from '../../store';
import {setDraw, setLayersPopup} from '../../store/maps';

export const closePopup = () =>{
  const map = store.getState().maps.map;
  const drawPopup = store.getState().maps.draw;
  const customerPopup = store.getState().maps.customerPopup;
  const layersPopup = store.getState().maps.layersPopup.visible;

  if (drawPopup) {
    store.dispatch(setDraw(null));
    map.removeInteraction(drawPopup);
  }

  if (customerPopup)
    customerPopup.setPosition(undefined);

  if (layersPopup)
    store.dispatch(setLayersPopup(false));
}