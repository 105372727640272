import Chart from 'chart.js/auto';
import {handleDelay} from '../animations/chartAnimations';

export const createCustomerChart = () => {
  const ctx = document.getElementById('customer-chart').getContext('2d');

  let delayed = false;

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: ['Y1', 'Y2', 'Y3', 'Y4', 'Y5'],
      datasets: [
        {
          data: [],
          backgroundColor: '#C0C0C0',
        }],
    },
    options: {
      responsive: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      animation: {
        delay: (context) => handleDelay(context, delayed),
      },
      scales: {
        x: {
          stacked: true,
          color: 'red',
        },
        y: {
          stacked: true,
        },
      },
    },
  });
};