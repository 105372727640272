import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 60px;
  padding: 10px 0;
  color: #fff;
  background-color: rgba(99, 106, 99, 0.8);
  border-radius: 20px;
  margin: 15px;
  z-index: 2;
`;

export const BtnControl = styled.button`
  margin: 10px 0;
  background: transparent;
  cursor: pointer;
  border: none;
`;

export const IconControl = styled.i`
  font-size: 22px;
  &:hover{
    color: #5BA0F1;
  }
`;