import {InboxOutlined} from '@ant-design/icons';
import {Dragger} from '../../styled/components/Forms/ClientsCreateForm';

export const ClientsDragger = ({onRemove, beforeUpload, onChange}) => {
  return (
      <Dragger name="file"
               onRemove={onRemove}
               beforeUpload={beforeUpload}
               onChange={onChange}
               maxCount={1}>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{color: 'rgb(91, 160, 241)'}}/>
        </p>
      </Dragger>
  );
};