import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: ${({visible}) => visible ? '0%' : '100%'};
  top: 0;
  width: 100vw;
  transition: 1s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 100%;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`;
