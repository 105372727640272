import {
  Route,
  Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NotFound } from '../pages/NotFound';

export function PrivateRoute({ children, ...rest }) {
  const profile = useSelector(state => state.profile.profile);
  const subDomain = window.location.host.split('.')[0];
  return <Route
    {...rest}
    render={({ location }) => {
      if (profile) {

        if (profile.role === 'user') {

          if (profile.isSite2FaEnable === true) {
            if (profile.isTwoFactorEnabled === false) {
              if (location.pathname !== '/setting') {
                return <Redirect to={{ pathname: '/setting' }} />;
              }

            }
          }
          if (location.pathname === '/')
            return <Redirect to={{ pathname: '/map' }} />;

          if (location.pathname !== '/map' && location.pathname !== '/dashboard' && location.pathname !== '/setting')
            return <NotFound />

          if (subDomain !== profile.company)
            return window.location.replace(`http://${profile.company}.${process.env.REACT_APP_DOMAIN}`);

          if (rest.path === '/admin/dashboard')
            return <Redirect to={{ pathname: '/map' }} />;

        }

        if (profile.role === 'admin') {
          if (window.location.host !== process.env.REACT_APP_DOMAIN)
            return window.location.replace(`http://${process.env.REACT_APP_DOMAIN}/admin/dashboard`);

          if (rest.path === '/') {
            return <Redirect
              to={{ pathname: 'admin/dashboard', state: { from: location } }} />;
          }

        }

        return children;
      }

      return <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />;
    }}
  />;
}
