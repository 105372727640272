import React from 'react';
import Header from "./Header";
import Footer from "./Footer";

function Layout({children}) {
    return (
        <div className="wrapper-landing loaded">
            <Header/>
            <main>
                {children}
            </main>
            <Footer/>
        </div>
    );
}

export default Layout;
