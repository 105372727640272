import {
  Logo,
  LogoDescription,
  LogoWrapper,
} from '../../styled/components/Pages/Home';

export const HomeLogo = () => {
  return (
      <LogoWrapper>
        <Logo src="/geoptimise-logo-blue.png" alt="Company logo"/>
        <LogoDescription>Find more of your best customers</LogoDescription>
      </LogoWrapper>
  )
}