import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import { message, Spin, Space } from 'antd';
import { getAdminSettingAsync, setAdminSettingAsync } from '../../store/settings';
import { Table, Switch } from "antd";
import { Layout } from 'antd';
import { geoptimiseColor } from '../../styled/components/Variables/variables';

const { Content } = Layout;

export function AdminSetting() {
  const dispatch = useDispatch();
  const { admin_setting: settings } = useSelector(state => state.settings);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);  // Loader state

  useEffect(() => {
    setLoading(true);
    dispatch(getAdminSettingAsync()).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    setData(settings);
  }, [settings]);

  const handleToggle2FA = async (checked, record) => {
    try {
      setLoading(true);
      record.setting_value = checked;
      const formData = new FormData();
      formData.append("setting_key", record.setting_key);
      formData.append("setting_value", checked);
      await dispatch(setAdminSettingAsync(formData));
      message.success(`Two-Factor Authentication ${checked ? "enabled" : "disabled"}`);

    } catch (error) {
      console.log(error);
      message.error("Error updating 2FA setting");
    } finally {
      setLoading(false);
      dispatch(getAdminSettingAsync());
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "setting_name", key: "setting_name" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        record.setting_key === "2fa_enabled" ? (
          <Switch
            checked={record.setting_value === "true"}
            onChange={(checked) => handleToggle2FA(checked, record)}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            style={{
              backgroundColor: record.setting_value === "true" ? "#00e109" : undefined,
            }}
          />

        ) : (
          '-'
        )
      )
    },
  ];

  return (
    <Content style={{ padding: '0 24px', marginTop: '4%', minHeight: 280 }}>
      <div style={{ padding: 20, backgroundColor: '#3b3f40' }}>
        <h2 style={{ marginBottom: 20, color: "#FFF" }}>Settings</h2>
        <hr />
        <Table
          dataSource={data}
          columns={columns}
          rowKey="id"
          loading={loading}
          style={{ marginTop: 20 }}
        />
      </div>
    </Content>
  );
};
