import {Feature} from 'ol';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import {Fill, Stroke, Text} from 'ol/style';
import {
  bronzeColor,
  goldColor,
  platinumColor, silverColor,
} from '../../styled/components/Variables/variables';
import {fromLonLat} from 'ol/proj';

const createFeature = (coordinate, color, customer ,categoryColor, category) => {
  const featureToAdd = new Feature({geometry: new Point(coordinate),name : customer, categoryColor:categoryColor, category});
  const style = new Style({
    text: new Text({
      text: '',
      font: '900 18px "Font Awesome 5 Free"',
      fill: new Fill({
        color,
      }),
      stroke: new Stroke({
        color: 'rgba(0,0,0)',
        width: 3
      })
    }),
  });

  featureToAdd.setStyle(style);

  return [featureToAdd];
};

export const createClientsMarker = (source,clients) => clients.forEach(
    (client, index) => {
      const firstGroup = Math.ceil((clients.length * 25) / 100);
      const secondGroup = Math.ceil((clients.length * 50) / 100);
      const thirdGroup = Math.ceil((clients.length * 75) / 100);
      let colorMarker = 'red';
      let category = '';

      if (index < firstGroup) {
        colorMarker = platinumColor;
        category= 'platinum';
      }

      if (index < secondGroup && index >= firstGroup) {
        colorMarker = goldColor;
        category= 'gold';
      }

      if (index < thirdGroup && index >= secondGroup) {
        colorMarker = silverColor;
        category= 'silver';
      }

      if (index >= thirdGroup) {
        colorMarker = bronzeColor;
        category= 'bronze';
      }

      const features = createFeature(
          fromLonLat(client.location.coordinates),
          colorMarker,client.customer_id,colorMarker, category);

      source.addFeatures(features);
    });