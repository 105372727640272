import styled from 'styled-components';
import {geoptimiseColor} from '../Variables/variables';
import {Menu, Space, Dropdown} from 'antd';
import {MenuOutlined} from '@ant-design/icons';

export const Container = styled.div`
  background-color: #3b3f40;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  border-bottom: 3px solid ${geoptimiseColor};
  background-color: #3b3f40;
  z-index: 6;
`;

export const WrapperContent = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 25px 25px 15px 25px;

  @media (max-width: 2200px) {
    padding: 25px 25px 5px 25px;
  }

  @media (max-width: 1200px) {
    justify-content: space-between;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 40%;
`;

export const Logo = styled.img`
  width: 40%;

  @media (max-width: 800px) {
    width: 80%;
  }
`;

export const LogoDescription = styled.h3`
  margin: 0 0 0 12px;
  color: rgb(91 160 241);
  font-style: italic;
  font-weight: bold;

  @media (max-width: 1500px) {
    font-size: 14px;
  }

  @media (max-width: 1200px) {
    font-size: 12px;
  }

  @media (max-width: 1000px) {
    font-size: 10px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 40%;

  @media (max-width: 1000px) {
    width: 30%;
  }
`;

export const NavList = styled.ul`
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding: 0;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const NotificationsStatistic = styled.span`
  color: #fff;
  background-size: 400% 400%;
  animation: ${({animation}) => animation ? 'gradient 3s ease infinite' : ''};
  margin-left: 5px;
  @keyframes gradient {
    0% {
      color: #ee7752;
    }
    50% {
      color: #e73c7e;
    }
    100% {
      color: #23a6d5;
    }
  }

  @media (max-width: 800px) {
    font-size: 16px;
    color: inherit;
  }
`;

export const NotificationsIcon = styled.i`
  color: #fff;
  background-size: 400% 400%;
  animation: ${({animation}) => animation ? 'gradient 3s ease infinite' : ''};
  @keyframes gradient {
    0% {
      color: #ee7752;
    }
    50% {
      color: #e73c7e;
    }
    100% {
      color: #23a6d5;
    }
  }

  @media (max-width: 800px) {
    font-size: 16px;
    color: inherit;
  }
`;

export const NavItem = styled.li`
  margin-right: 30px;
  list-style-type: none;

  @media (max-width: 1500px) {
    margin-right: 10px;
  }

  @media (max-width: 1000px) {
    margin-right: 5px;
  }

  & > li {
    @media (max-width: 800px) {
      &:hover {
        background-color: ${geoptimiseColor};
      }
    }
  }

  & div {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${geoptimiseColor};
    }

    @media (max-width: 1500px) {
      font-size: 16px;
    }

    @media (max-width: 1000px) {
      font-size: 12px;
    }
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ToggleRight = styled.div`
  font-size: 18px;
  width: 130px;
  color: ${props => props.toggleColor};
  background: ${props => props.toggleBackground};
  border-radius: 0 10px 10px 0;
  font-weight: bold;
  padding: 5px 20px;
  cursor: pointer;
  transition: all 0.5s ease-out;

  @media (max-width: 1500px) {
    font-size: 14px;
    width: 130px;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
    width: 100px;
  }
`;

export const ToggleLeft = styled.div`
  font-size: 18px;
  width: 130px;
  color: ${props => props.toggleColor};
  background: ${props => props.toggleBackground};
  border-radius: 10px 0 0 10px;
  text-align: center;
  font-weight: bold;
  padding: 5px 20px;
  cursor: pointer;
  transition: all 0.5s ease-out;

  @media (max-width: 1500px) {
    font-size: 14px;
    width: 130px;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
    width: 100px;
  }
`;

export const ErrorNotificationText = styled.div`
  font-size: 14px;
  margin: 10px 0;
`;

export const NotificationText = styled.div`
  font-size: 14px;
  margin: 10px 0;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const MenuWrapper = styled(Space)`
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`;

export const MenuIcon = styled(MenuOutlined)`
  color: ${geoptimiseColor};
  font-size: 20px;
  cursor: pointer;
`;

export const CollapsedMenu = styled(Menu)`
  background-color: rgb(59, 63, 64);
  padding:0;
  margin-top: 0;
  & > li {
    color: ${geoptimiseColor};
  }
`;

export const CollapsedMenuWrapper = styled(Dropdown)`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;