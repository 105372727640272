import {GeodemographicsOverlay} from './GeodemographicsOverlay';
import {HouseholdOverlay} from './HouseholdOverlay';
import {PostcodeBoundariesOverlay} from './PostcodeBoundariesOverlay';
import {PropertyAgeOverlay} from './PropertyAgeOverlay';
import {ConsumerVulnerabilityOverlay} from "./ConsumerVulnerabilityOverlay";
import {InternetUserClassificationOverlay} from "./InternetUserClassificationOverlay";
import {AffluenceOverlay} from "./AffluenceOverlay";
import {PostcodeVisualisation} from "./PostcodeVisualisation";

export const MapOverlays = ({extentUnitedKingdom}) => {
  return (
      <>
        <GeodemographicsOverlay/>
        <HouseholdOverlay extentUnitedKingdom={extentUnitedKingdom}/>
        <PostcodeBoundariesOverlay extentUnitedKingdom={extentUnitedKingdom}/>
        <PropertyAgeOverlay extentUnitedKingdom={extentUnitedKingdom}/>
        {/* <ConsumerVulnerabilityOverlay extentUnitedKingdom={extentUnitedKingdom}/> */}
        <AffluenceOverlay extentUnitedKingdom={extentUnitedKingdom}/>
        <PostcodeVisualisation extentUnitedKingdom={extentUnitedKingdom}/>
        <InternetUserClassificationOverlay extentUnitedKingdom={extentUnitedKingdom}/>
      </>
  );
};
