import {
  Btn,
  Form, Label,
  Line, Select,
} from '../../styled/components/Forms/UserPlanForm';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {CloseBtn, WrapperBtn} from '../../styled/components/Common/common';
import {Modal} from 'antd';
import {useSelector} from "react-redux";

export function UserPlansForm({onUpdate, user}) {
    const plans = useSelector(state => state.users.plans);

    const formik = useFormik({
    initialValues: {
      visible: false,
      plan_id: '',
    },
    validationSchema: Yup.object({
      plan_id: Yup.string().required(),
    }),
    onSubmit: async (values, formikHelpers) => {
      onUpdate(user.id, values);
      formikHelpers.resetForm();
    },
  });

  return (
      <>
        <Modal
            footer={false}
            closable={false}
            width={0}
            visible={formik.values.visible}
        >
          <Form onSubmit={formik.handleSubmit}>
            <Line/>
            <WrapperBtn>
              <CloseBtn onClick={() => formik.setFieldValue('visible', false)}/>
            </WrapperBtn>
            <Label htmlFor="email">Plan</Label>
            <Select
                defaultValue={user.plan}
                onChange={(value) => formik.setFieldValue('plan_id', value)}
            >
                {plans.map(plan =>
                        <Select.Option
                            value={String(plan.id)}
                            key={plan.id}
                        >
                            {plan.name}
                        </Select.Option>
                )}
            </Select>
            <Btn type="submit">Change Plan</Btn>
          </Form>
        </Modal>
        <div
            onClick={() => formik.setFieldValue('visible', true)}
        >
            Plan
        </div>
      </>
  );
}
