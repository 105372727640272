import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  box-sizing: border-box;
  width: calc(15px * 4);
  height: 15px;
  position: relative;
  margin: 0 0 0 10px;
`;

export const Rhombus = styled.div`
  height: 15px;
  width: 15px;
  background-color: rgb(91, 160, 241);
  left: calc(15px * 4);
  position: absolute;
  margin: 0 auto;
  border-radius: 2px;
  transform: translateY(0) rotate(45deg) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;

  &:nth-child(1) {
    animation-delay: calc(2500ms * 1 / -1.5);
  }

  &:nth-child(2) {
    animation-delay: calc(2500ms * 2 / -1.5);
  }

  &:nth-child(3) {
    animation-delay: calc(2500ms * 3 / -1.5);
  }

  @keyframes looping-rhombuses-spinner-animation {
    0% {
      transform: translateX(0) rotate(45deg) scale(0);
    }
    50% {
      transform: translateX(-233%) rotate(45deg) scale(1);
    }
    100% {
      transform: translateX(-466%) rotate(45deg) scale(0);
    }
`;