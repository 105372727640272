import {CustomerPopup} from './CustomerPopup';
import {MapLayersPopup} from './MapLayersPopup';
import {PotentialCustomersPopup} from './PotentialCustomersPopup';
import {OverlayPopup} from './OverlayPopup';
import {PostcodePopup} from './PostcodePopup';
import {CategoryPopup} from './CategoryPopup';
import {GeocoderPopup} from './GeocoderPopup';
import { SavedAreaPopup } from '../../components/Popups/SavedAreaPopup';
export const MapPopups = ({onClosePopups}) => {
  return (
      <>
        <SavedAreaPopup />
        <CustomerPopup/>
        <GeocoderPopup />
        <MapLayersPopup onClosePopups={onClosePopups}/>
        <PotentialCustomersPopup/>
        <CategoryPopup onClosePopups={onClosePopups}/>
        <OverlayPopup onClosePopups={onClosePopups}/>
        <PostcodePopup onClosePopups={onClosePopups}/>
      </>
  );
};