import {Main, Wrapper} from '../../styled/components/Pages/Admin';
import {UserControl} from '../Controls/UserControl';
import {UserList} from '../Lists/UserList';
import {UsersPaginate} from '../Paginates/UsersPaginate';

export function AdminDashboard({
  onCreateUser,
  onDeleteUser,
  onSearchUser,
  onUpdateUser,
  onTwoFAUser
}) {

  return (
      <Main>
        <Wrapper>
          <UserControl onCreateUser={onCreateUser} onSearchUser={onSearchUser} />
          <UserList onUpdateUser={onUpdateUser} onDeleteUser={onDeleteUser} onTwoFAUser={onTwoFAUser}/>
          <UsersPaginate/>
        </Wrapper>
      </Main>
  );
}