import {useEffect} from 'react';
import {Overlay} from 'ol';
import {useDispatch, useSelector} from 'react-redux';
import {setClientPopup} from '../../store/maps';
import {CustomerPopupCard} from '../Cards/CustomerPopupCard';
import {
    createCustomerPopup,
} from '../../helpers/popup/createCustomerPopup';

export const CustomerPopup = () => {
    const map = useSelector(state => state.maps.map);
    const clients = useSelector(state => state.clients.clients);
    const draw = useSelector(state => state.maps.draw);
    const clientPopup = useSelector(state => state.maps.clientPopup);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!map)
            return null;

        if (clientPopup.overlay) {
            return null;
        }

        const element = document.getElementById('popup');
        const overlay = new Overlay({
            element,
            positioning: 'bottom-center',
            stopEvent: false,
        });

        dispatch(setClientPopup({overlay}));
        map.addOverlay(overlay);
    }, [map, dispatch, clientPopup.overlay]);

    useEffect(() => {
        if (!clientPopup.overlay || !clients.length)
            return null;

        const handleClickCustomer = (event) => {
            if (draw)
                return null;

            const feature = map.forEachFeatureAtPixel(event.pixel,
                feature => feature);

            if (!feature || !clientPopup.overlay ||
                feature.getGeometry().getType() !==
                'Point')
                return null;

            createCustomerPopup(feature.get('name'));
        };

        map.on('click', handleClickCustomer);
    }, [clientPopup.overlay, clients, draw, map]);

    return (
        <div id="popup">
            <CustomerPopupCard/>
        </div>
    );
};
