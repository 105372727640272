import styled from 'styled-components';

export const PopupWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 140px;
  background-color: rgba(99, 106, 99, 0.9);
  color: #fff;
  z-index: 100;
`;

export const PopupTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  background-color: rgba(80, 80, 80, 0.7);

  & i {
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
`;

export const PopupDescription = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px 10px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
`;

export const UploadTime = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin: 0 20px;
`;
export const NumberUpload = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin: 0 10px 0 0;
`;

