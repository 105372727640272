import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {createOverlayLayer} from '../../helpers/layers/createOverlayLayer';

export const HouseholdOverlay = ({extentUnitedKingdom}) => {
  const map = useSelector(state => state.maps.map);

  useEffect(() => {
    if (!map)
      return null;

    createOverlayLayer(map, 'Household', {
      'LAYERS': 'geoptimise:oac_grp_exp_4326',
    }, extentUnitedKingdom);
  }, [map, extentUnitedKingdom]);

  return null;
};