import styled from "styled-components";

export const ImageWrapper = styled.div`
    width: 900px;
    height: 450px;
    box-shadow: 5px 5px 24px 5px rgb(0 0 0 / 25%);
    margin: -420px auto;

  @media (max-width: 992px) {
    display: none;
  }
`
export const ImageBox = styled.div`
  width: 900px;
  height: 450px;
  position: relative;
  transition: all .3s linear;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s linear;
  }
`;
