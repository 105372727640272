import styled from 'styled-components';

export const PopupWrapper = styled.div`
  width: 500px;
  position: absolute;
  right: 50px;
  top: 50px;
  padding: 10px;
  background-color: rgba(99, 106, 99, 0.9);
  color: #fff;

  @media (max-width: 1500px){
    width: 350px;
  }
  
`;

export const PopupTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 0;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;

  & i {
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
`;

export const PopupColor = styled.div`
  width: 100%;
  height: 20px;
  margin: 10px 0;
  background-color: ${({background}) => background};
`;

export const PopupDescription = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const PopupInfo = styled.div`
  margin: 10px 0;
  font-size: 18px;
`;

export const ControlClient = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  & i {
    margin: 0 10px;
  }
`;