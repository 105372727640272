import apiClient from '../../libs/apiClient';
import {message} from 'antd';

export const categoriesActionType = {
  GET_CATEGORIES:'GET_CATEGORIES',
  SET_CATEGORY_POPUP:'SET_CATEGORY_POPUP',
  DELETE_CATEGORY_POPUP:'DELETE_CATEGORY_POPUP'
}

export  const getCategories = (payload)=> ({
  type: categoriesActionType.GET_CATEGORIES,
  payload
})

export  const setCategoryPopup = (payload)=> ({
  type: categoriesActionType.SET_CATEGORY_POPUP,
  payload
})

export  const deleteCategoryPopup = (payload)=> ({
  type: categoriesActionType.DELETE_CATEGORY_POPUP,
  payload
})

export const getCategoriesAsync = () => async dispatch =>{
  try {
    const {data: response} = await apiClient.get('categories');

    return dispatch(getCategories(response));
  } catch (e) {
    message.error('Internal server error.');
  }
}