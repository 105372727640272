import {geocoderActionType} from './actions';

const initialState = {
  geocoder: null,
  geocoderPopup: {
    overlay: null,
    visible: false,
    address: null,
  },
};

export const geocoderReducer = (state = initialState, action) => {
  switch (action.type) {

    case geocoderActionType.SET_GEOCODER:
      return {...state, geocoder: action.payload};

    case geocoderActionType.SET_GEOCODER_POPUP:
      return {...state, geocoderPopup: {...state.geocoderPopup, ...action.payload}};

    default:
      return state;
  }
};
