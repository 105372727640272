import {
  BtnControl,
  IconControl,
} from '../../styled/components/Controls/MapControl';
import {setCurrentZoomIndex} from '../../store/maps';
import {useDispatch, useSelector} from 'react-redux';

export const NextExtentBtn = () => {
  const map = useSelector(state => state.maps.map);
  const zooms = useSelector(state => state.maps.zooms);
  const currentZoomIndex = useSelector(state => state.maps.currentZoomIndex);
  const dispatch = useDispatch();

  const handleNextExtentZoom = () => {
    const view = map.getView();

    if (currentZoomIndex === zooms.length - 1)
      return null;

    view.setZoom(zooms[currentZoomIndex + 1]);
    return dispatch(setCurrentZoomIndex(currentZoomIndex + 1));
  };

  return (
    <BtnControl
      title='Zoom to next extent'
      onClick={handleNextExtentZoom}
    >
      <IconControl className="fas fa-arrow-circle-right"/>
    </BtnControl>
  );
};
