import {HeaderImage, HeaderLogo} from '../../styled/components/Pages/Admin';

export function AdminLogo() {
  return (
      <HeaderLogo>
        <HeaderImage
            src="/geoptimise-logo-blue.png"
            alt="Company's logo"/>
      </HeaderLogo>
  );
}