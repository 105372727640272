import {useEffect, useMemo, useState} from 'react';
import {Map as OlMap, View} from 'ol';
import {ClientsMap, Container} from '../../styled/components/Maps/Map';
import {useDispatch, useSelector} from 'react-redux';
import {setMap, setZoom} from '../../store/maps';
import {fromLonLat} from 'ol/proj';
import {MapLayers} from '../Layers/MapLayers';
import {MapPopups} from '../Popups/MapPopups';
import {MapOverlays} from '../Overlays/MapOverlays';
import {useParams} from 'react-router-dom';
import {MapNavigation} from '../Navigations/MapNavigation';
import {setZoomByClients} from '../../helpers/map/setZoomByClients';
import {MapGeocoder} from '../Geocoder/MapGeocoder';
import {setLoading} from "../../store/loading";
import MainSpinner from "../Spinners/MainSpinner";

export function Map({onClosePopups}) {
    const map = useSelector(state => state.maps.map);
    const loading = useSelector(state => state.loading.loading);
    const clients = useSelector(state => state.clients.clients);
    const {page} = useParams();
    const dispatch = useDispatch();
    const centerUnitedKingdom = fromLonLat([-3.103468, 55.689950]);
    const extentUnitedKingdom = useMemo(() => [
        -2819183.393431846,
        6261082.591444467,
        2123868.272959183,
        8690886.525526553], []);


    useEffect(() => {
        if (!map) {
            return null;
        }
        map.once('precompose', () => dispatch(setLoading(true)));
        map.on('rendercomplete', () => dispatch(setLoading(false)));
    }, [map]);

    useEffect(() => {
        if (map)
            return null;

        dispatch(setMap(new OlMap({
            controls: [],
            target: 'map',
            layers: [],
            view: new View({
                center: centerUnitedKingdom,
                extent: extentUnitedKingdom,
                zoom: 5,
                maxZoom: 14,
                minZoom: 5,
            }),
        })));
    }, [dispatch, centerUnitedKingdom, extentUnitedKingdom, map]);

    useEffect(() => {
        if (!map)
            return null;

        const extent = map.getView().calculateExtent();
        const resolution = 0;

        const width = map.getSize()[0] * resolution;
        const height = map.getSize()[1] * resolution;

        const view = new View({
            projection: map.getView().getProjection(),
            extent: [
                extent[0] + (width / 2),
                extent[1] + (height / 2),
                extent[2] - (width / 2),
                extent[3] - (height / 2)],
            center: map.getView().getCenter(),
            resolution: resolution,
            zoom: 1,
            maxZoom: map.getView().getMaxZoom(),
            minZoom: map.getView().getMinZoom(),
        });
        map.setView(view);
        map.getView().setZoom(5);
    }, [map]);

    useEffect(() => {
        if (!map)
            return null;

        map.on('pointerdrag', () => map.getViewport().style.cursor = 'all-scroll');
        map.on('pointermove', () => map.getViewport().style.cursor = '');
        map.on('wheel', () => dispatch(setZoom(map.getView().getZoom())));
    }, [map, dispatch]);

    useEffect(() => {
        if (page !== 'map')
            return null;

        if (!clients.length) {
            if (!map) return
            map.getView().setCenter(centerUnitedKingdom);
            map.getView().setZoom(5)
            return
        }

        setZoomByClients(map, clients);
    }, [clients, map, page]);

    return (
        <Container visible={page === 'map'} className="map-container">
            {loading && <MainSpinner/>}
            <ClientsMap id="map">
                <MapGeocoder/>
                <MapNavigation/>
                <MapPopups onClosePopups={onClosePopups}/>
                <MapLayers/>
                <MapOverlays extentUnitedKingdom={extentUnitedKingdom}/>
            </ClientsMap>
        </Container>
    );
}
