import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Spin} from "antd";
import bg from "../../assets/img/pattern.svg";
import Logo from "../../assets/img/geoptimise-logo-blue.svg";
import {Wrapper, Box, BoxHeader, BoxBody, BoxSpinner} from "../../styled/landing/verify.styles";
import apiClient from "../../libs/apiClient";
import {textFadeTopAnimation, transitionEffect} from "../../helpers/animations/landingAnimations";

function VerifyEmail() {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const url = window.location.href.split('=')[1];
    useEffect(() => {
        const sendToken = async () => {
            await apiClient.post('auth/registration-request/notify-admin', {
                hash: url
            }).then((response) => {
                setSuccess(true)
            }).catch(error => {
                setError(true);
            });
        };

        (async () => await sendToken())();
    }, [])

    return (
        <Wrapper>
            <div className="pattern" style={{backgroundImage: `url(${bg})`}}></div>
            <Box>
                <BoxHeader>
                    <img src={Logo} alt="logo"/>
                </BoxHeader>
                {!success && !error && <BoxBody>
                    <BoxSpinner pending><Spin size="large"/></BoxSpinner>
                </BoxBody>}
                {success && (
                    <BoxBody verifyInfo initial={textFadeTopAnimation.initial}
                             whileInView={textFadeTopAnimation.inView}
                             viewport={{once: true}}
                             transition={transitionEffect.transition}>
                        <p>Thank you. One of our representatives will be in touch with you shortly.</p>
                        <Link to="/">Return to the home page</Link>
                    </BoxBody>
                )}
                {error && !success && (
                    <BoxBody verifyInfo initial={textFadeTopAnimation.initial}
                             whileInView={textFadeTopAnimation.inView}
                             viewport={{once: true}}
                             transition={transitionEffect.transition}>
                        <p>
                            This registration has already been confirmed. For assistance please
                            <a
                                href="https://www.innovation-mapping.com/contact-innovation-mapping/"
                                target="_blank"> contact us.
                            </a>
                        </p>
                        <Link to="/">Return to the home page</Link>
                    </BoxBody>
                )}
            </Box>
        </Wrapper>
    );
}

export default VerifyEmail;
