import {
  NavBtn,
  Navigation,
  NavItem,
  NavList,
} from '../../styled/components/Pages/Admin';
import { Link } from 'react-router-dom';

export function AdminNavigation({ onLogout }) {
  return (
    <Navigation>
      <NavList>
        <NavItem>
          <Link to="/admin/dashboard">
            <i className="fas fa-users-cog" /> Dashboard
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/admin/setting">
            <i className="fas fa-cog" /> Setting
          </Link>
        </NavItem>
        <NavItem onClick={onLogout}>
          <NavBtn>
            <i className="fas fa-sign-out-alt" /> Logout
          </NavBtn>
        </NavItem>
      </NavList>
    </Navigation>
  );
}