import {Feature} from 'ol';
import {Polygon} from 'ol/geom';
import {getCenter} from 'ol/extent';
import {fromLonLat} from 'ol/proj';

export const setZoomByClients = (map, clients) => {
    const maxX = Math.max.apply(Math,
        clients.map(client => client.location.coordinates[0]));
    const maxY = Math.max.apply(Math,
        clients.map(client => client.location.coordinates[1]));
    const minX = Math.min.apply(Math,
        clients.map(client => client.location.coordinates[0]));
    const minY = Math.min.apply(Math,
        clients.map(client => client.location.coordinates[1]));
    const data = [[minX, minY], [maxX, maxY]].map(
        coordinates => fromLonLat(coordinates));
    const polygon = new Feature({
        type: 'Polygon',
        geometry: new Polygon([data]),
    });

    const coordinates = getCenter(polygon.getGeometry().getExtent());

    map.getView().setCenter(coordinates);

    switch (true) {
        case minY >= 49 && maxY <= 51:
            return map.getView().setZoom(8);

        case minY >= 51 && maxY <= 53:
            return map.getView().setZoom(8);

        case minY >= 53 && maxY <= 56:
            return map.getView().setZoom(8);

        case minY >= 56 && maxY <= 58:
            return map.getView().setZoom(8);

        case minY >= 58 && maxY <= 60:
            return map.getView().setZoom(8);

        case minY >= 49 && maxY <= 54:
            return map.getView().setZoom(7);

        case minY >= 54 && maxY <= 60:
            return map.getView().setZoom(7);

        default:
            return map.getView().setZoom(5);
    }
}
