import {
  Container, Main,
  ToggleForm,
  ToggleReset,
} from '../styled/components/Pages/Login';
import { LoginHeader } from '../components/Headers/LoginHeader';
import { LoginFooter } from '../components/Footers/LoginFooter';
import { useState } from 'react';
import { LoginResetForm } from '../components/Forms/LoginResetForm';
import { LoginForm } from '../components/Forms/LoginForm';
import { Api } from '../api';
import { useHistory } from 'react-router-dom';
export function Login() {
  const [resetPassword, setResetPassword] = useState();
  const history = useHistory();
  const handleLoginUser = async credentials => {
    //const { isTwoFactorEnabled, redirect_url } = await Api.Users.login(credentials);
     const response = await Api.Users.login(credentials);
     if (!response) {
       //throw new Error("API response is undefined");
       console.log("API response is undefined");
     }
 
     const { isTwoFactorEnabled, redirect_url } = response || null;
     //console.log("Login Success:", { isTwoFactorEnabled, redirect_url });

    if (isTwoFactorEnabled) {
      history.push({
        pathname: '/two-factor-auth',
        state: { credentials }
      });
      return;
    }

    if (redirect_url) {
      if (redirect_url === 'admin/dashboard') {
        return window.location = `${redirect_url}`;
      }

      return window.location = `${redirect_url}/map`;
    }
  };

  const handleForgotPassword = email => Api.Users.forgotPassword(email);

  const showToggle = resetPassword &&
    <ToggleForm onClick={() => setResetPassword(false)}><i
      className="fas fa-pencil-alt" />Login
    </ToggleForm>;

  const passwordReset = !resetPassword &&
    <ToggleReset onClick={() => setResetPassword(true)}>Forgot your
      password?</ToggleReset>;

  const formControll = resetPassword
    ? <LoginResetForm onSubmit={handleForgotPassword} />
    : <LoginForm onSubmit={handleLoginUser} />;

  return (
    <Container>
      <LoginHeader />
      <Main className="main">
        {showToggle}
        {formControll}
        {passwordReset}
      </Main>
      <LoginFooter />
    </Container>
  );
}
