import {clientsActionType} from './actions';

const initialState = {
  clients: [],
  inactiveClients: [],
  notifications: [],
  clientUploads: [],
  uploadingFiles:[],
  visibleUploadPopup:true,
  visibleUploadErrorPopup: false,
};

export const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case clientsActionType.GET_CLIENTS:
      return {...state, clients: action.payload};

    case clientsActionType.ADD_CLIENTS_BY_BATCH_ID:
      return {...state, clients: [...state.clients, ...action.payload]};

    case clientsActionType.GET_INACTIVE_CLIENTS:
      return {...state, inactiveClients: action.payload};

    case clientsActionType.SET_UPLOADING_FILE:
      return {...state, uploadingFiles: [...state.uploadingFiles, {batchId:action.payload.batchId, timerId: action.payload.timerId }]};

    case clientsActionType.DELETE_UPLOADING_FILE:
      return {...state, uploadingFiles: state.uploadingFiles.filter(upload=>upload.batchId !== action.payload)};

    case clientsActionType.DELETE_INACTIVE_CLIENTS:
      return {...state, inactiveClients: action.payload};

    case clientsActionType.GET_CLIENTS_NOTIFICATIONS:
      return {...state, notifications: action.payload};

    case clientsActionType.GET_CLIENT_UPLOADS:
      return {...state, clientUploads: action.payload};

    case clientsActionType.SET_VISIBLE_UPLOAD_POPUP:
      return {...state, visibleUploadPopup: action.payload};

    case clientsActionType.SET_VISIBLE_UPLOAD_ERROR_POPUP:
      return {...state, visibleUploadErrorPopup: action.payload};

    case clientsActionType.DELETE_CLIENT_UPLOAD:
      return {...state,
        clientUploads: state.clientUploads.filter(
            client => client.batch_id !== action.payload),
      };

    case clientsActionType.CHANGE_UPLOAD:
      return {
        ...state, clientUploads: state.clientUploads.map(upload => {

          if (upload.id === action.payload.id) {
            upload.disabled = action.payload.disabled;
          }

          return upload;
        }),
      };

    case clientsActionType.SET_CLIENT_UPLOAD:
      return {
        ...state,
        clientUploads: [...state.clientUploads, action.payload],
      };

    default:
      return state;
  }
};
