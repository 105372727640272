import {settingActionType} from './actions';

const initialState = {
  setting_two_factor: [],
  admin_setting:[],

};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {

    case settingActionType.GET_SETTING_TWO_FACTOR:
      return {
        ...state, setting_two_factor: action.payload.data,
      };

      
    case settingActionType.GET_ADMIN_SETTING:
      return {
        ...state, admin_setting: action.payload,
      };

    
    default:
      return state;
  }
};
