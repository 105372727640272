import styled from 'styled-components';
import {Radio} from 'antd';

export const Container = styled.div`
  position: relative;
  right: ${({visible}) => visible ? '0%' : '100%'};
  top: 0;
  transition: 1s ease;
  width: 100%;
  height: 100%;
  & svg {
    -moz-box-shadow: -5px -5px 5px #888;
    -webkit-box-shadow: -5px -5px 5px #888;
    box-shadow: -5px -5px 5px #888;
  }
`;

export const ClientsMap = styled.div`
  width: 100%;
  height: 100%;
`;

export const WrapperLayersPopup = styled.div`
  display: ${({visible}) => visible ? 'block' : 'none'};
  position: absolute;
  z-index: 2;
  background-color: rgba(99, 106, 99, 0.8);
  left: 100px;
  top: 130px;
`;

export const MapLayersTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  color: #fff;
  background-color: rgba(80, 80, 80, 0.7);
  font-size: 18px;
  font-weight: bold;
  padding: 10px;

  & i {
    margin-left: 25px;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
`;

export const RadioWrapper = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 10px;

  & span {
    color: #fff;
    font-size: 16px;
  }
`;

export const LayersTitle = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 0 10px;
`;