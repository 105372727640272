import {
    Form, HelpDescription,
    Logo, Modal,
} from '../../styled/components/Forms/HelpGuideForm';

import {useDispatch, useSelector} from "react-redux";
import {setVisibleUploadErrorPopup} from "../../store/clients";

export const UploadErrorPopup = () => {
    const dispatch = useDispatch();
    const visibleUploadErrorPopup = useSelector(state => state.clients.visibleUploadErrorPopup)

    return (
        <Modal
            visible={visibleUploadErrorPopup}
            footer={null}
            width={600}
            centered={true}
            onCancel={() => dispatch(setVisibleUploadErrorPopup(false))}
        >
            <Form>
                <Logo
                    src="/geoptimise-logo-blue.png"
                    alt="Logo company"
                />
                <HelpDescription>
                    <span>Upload limit exceeded - please </span>
                    <a href="mailto:emailus@innovation-mapping.com">
                        contact Sales
                    </a>
                </HelpDescription>
            </Form>
        </Modal>
    );
}
