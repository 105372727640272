export const chartsActionType = {
  SET_TOTAL_CUSTOMERS_CHART:'SET_TOTAL_CUSTOMERS_CHART',
  SET_CUSTOMER_SPEND_CHART:'SET_CUSTOMER_SPEND_CHART',
  SET_CUSTOMER_GEODEMOGRAPHIC_CHART:'SET_CUSTOMER_GEODEMOGRAPHIC_CHART',
  SET_RADAR_CHART:'SET_RADAR_CHART',
  SET_SELECTION_GEODEMOGRAPHIC_CHART:'SET_SELECTION_GEODEMOGRAPHIC_CHART',
  SET_CUSTOMER_POPUP_CHART:'SET_CUSTOMER_POPUP_CHART',
};

export const setTotalCustomersChart = (payload) => ({
  type: chartsActionType.SET_TOTAL_CUSTOMERS_CHART,
  payload,
});

export const setCustomerSpendChart = (payload) => ({
  type: chartsActionType.SET_CUSTOMER_SPEND_CHART,
  payload,
});

export const setCustomerGeodemographicChart = (payload) => ({
  type: chartsActionType.SET_CUSTOMER_GEODEMOGRAPHIC_CHART,
  payload,
});

export const setRardarChart = (payload) => ({
  type: chartsActionType.SET_RADAR_CHART,
  payload,
});

export const setSelectionGeodemographicChart = (payload) => ({
  type: chartsActionType.SET_SELECTION_GEODEMOGRAPHIC_CHART,
  payload,
});

export const setCustomerPopupChart = (payload) => ({
  type: chartsActionType.SET_CUSTOMER_POPUP_CHART,
  payload,
});

