import {
  ControlClient,
  PopupColor, PopupDescription, PopupInfo,
  PopupTitle,
  PopupWrapper,
} from '../../styled/components/Popups/CategoryPopup';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {deleteCategoryPopup, setCategoryPopup} from '../../store/categories';
import {fromLonLat} from 'ol/proj';
import {
  updateFeaturesStyle,
} from '../../helpers/features/updateFeature';
import {
  createCustomerPopup,
} from '../../helpers/popup/createCustomerPopup';

export const CategoryPopup = ({onClosePopups}) => {
  const map = useSelector(state => state.maps.map);
  const overlays = useSelector(state => state.maps.overlays);
  const categories = useSelector(state => state.categories.categories);
  const clients = useSelector(state => state.clients.clients);
  const popup = useSelector(state => state.categories.popup);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map || !categories.length)
      return null;

    map.on('click', e => {
      const featureElem = map.forEachFeatureAtPixel(e.pixel, feature => feature);

      if (!featureElem || featureElem.getGeometry().getType() !== 'Polygon')
        return null;

      onClosePopups()

      categories.forEach(category => {
        if (category.group === featureElem.properties_.oac_group) {
          const customer = clients.find(client => client.oa_sa === featureElem.properties_.oa_sa);

          dispatch(setCategoryPopup({
            name: category.name,
            code: featureElem.properties_.oa_sa,
            visible: true,
            description: category.description,
            color: category.color,
            group: category.group,
            customer: customer ? customer.customer_id : null,
            batchId: customer ? customer.batch_id : null,
          }));

          createCustomerPopup(customer?.customer_id);
        }
      });
    });
  }, [map, categories, clients, dispatch, onClosePopups]);

  const handleClosePopup = () => {
    dispatch(deleteCategoryPopup());
    updateFeaturesStyle(map);
    map.getLayers().getArray().forEach(layer => {
      if (overlays.includes(layer.get('name'))) {
        layer.setVisible(true);
      }

      if(layer.get('name') === 'Geodemographics_legend'){
        map.removeLayer(layer);
      }
    });
  };

  const handleNextCustomer = (customer, code, location,batchId) => {
    dispatch(setCategoryPopup({
      ...popup,
      code,
      customer,
      batchId
    }));
    map.getView().setCenter(fromLonLat(location));
  };

  const handleChangeCategoryClient = (action) => {
    const clientsByCategory = clients.filter(
        client => client.group === popup.group);

    clientsByCategory.forEach((client, index) => {
      if (client.customer_id !== popup.customer || client.batch_id !== popup.batchId)
        return null;

      let nextClient = action === 'increase'
          ? clientsByCategory[index + 1]
          : clientsByCategory[index - 1];

      if (!nextClient)
        nextClient = action === 'increase'
            ? clientsByCategory[0]
            : clientsByCategory[clientsByCategory.length - 1];

      const {oa_sa, customer_id, location, batch_id} = nextClient;

      createCustomerPopup(nextClient.customer_id);
      handleNextCustomer(customer_id, oa_sa, location.coordinates, batch_id);
    });

  };

  if (!popup.visible)
    return null;

  const leftArrow = clients.filter(
      client => client.group === popup.group).length > 1
      ? <i className="fas fa-arrow-left"
           onClick={() => handleChangeCategoryClient('decrease')}/> : null;
  const rightArrow = clients.filter(
      client => client.group === popup.group).length > 1
      ? <i className="fas fa-arrow-right"
           onClick={() => handleChangeCategoryClient('increase')}/> : null;

  const control = popup.customer ?
      <ControlClient>
        {leftArrow}
        {popup.customer}
        {rightArrow}
      </ControlClient> : null;

  return <PopupWrapper>
    <PopupTitle>{popup.name}
      {control}
      <i onClick={handleClosePopup} className="fas fa-times"/>
    </PopupTitle>
    <PopupColor background={popup.color}/>
    <PopupDescription>{popup.description}</PopupDescription>
    <PopupInfo>Output Area code: {popup.code}</PopupInfo>
    <PopupInfo>OAC Group: {popup.group}</PopupInfo>
  </PopupWrapper>;
};
