import {
  DownloadBtn,
  ExampleText, ExampleWrapper,
} from '../../styled/components/Forms/ClientsCreateForm';
import {Link} from 'react-router-dom';

export const DownloadCSV = () => {
  return (
      <ExampleWrapper>
        <ExampleText>
          Download example csv file -
        </ExampleText>
        <Link to="/csv_example.csv" target="_blank" download>
          <DownloadBtn>
            Click me
            <i className="fas fa-file-upload"/>
          </DownloadBtn>
        </Link>
      </ExampleWrapper>
  )
}