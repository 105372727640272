import VectorTileSource from 'ol/source/VectorTile';
import {createXYZ} from 'ol/tilegrid';
import {MVT} from 'ol/format';
import VectorTileLayer from 'ol/layer/VectorTile';
import Style from 'ol/style/Style';
import {Fill, Stroke} from 'ol/style';

const simpleStyle = (feature, label, categories) => {

  if (label.group !== feature.get('oac_group'))
    return null;

  const category = categories.find(
      category => feature.get('oac_group') === category.group);



  return new Style({
    fill: new Fill({color: category.color}),
    stroke: new Stroke({color: '#cdcdcd', width: 0.1}),
  });
};

export const createMapLayer = (map, label, categories) => {
  map.getLayers().getArray().forEach(layer => {
    if (layer.get('name') === 'Geodemographics_legend')
      map.removeLayer(layer);

    if (layer.get('overlay'))
      layer.setVisible(false);
  });

  const source = new VectorTileSource({
    tileGrid: createXYZ({maxZoom: 14}),
    format: new MVT(),
    url: `${process.env.REACT_APP_GEOSERVER}gwc/service/tms/1.0.0/geoptimise:oac_2011_grp@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf`,
  });

  const layer = new VectorTileLayer({
    source,
    style: (feature) => simpleStyle(feature, label, categories),
    visible: true,
    name: 'Geodemographics_legend',
  });

  map.addLayer(layer);
};
