import styled from 'styled-components';

export const MainSpinnerWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(99, 106, 99, 0.9);
    height: calc(100vh - 75px);
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;
