import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const subDomain = window.location.host.split('.')[0];
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/',
});

apiClient.interceptors.request.use(config => {
  if (cookies.get('jwt'))
    config.headers.common.Authorization = `Bearer ${cookies.get('jwt')}`;

  if(window.location.host !== process.env.REACT_APP_DOMAIN)
    config.baseURL = process.env.REACT_APP_PRIVATE_API_URL.replace('{company}', subDomain);

  return config;
});

export default apiClient;