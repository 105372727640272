import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {reducers} from './reducers';
import thunk from 'redux-thunk';

const enhancers = process.env.REACT_APP_ENV === 'production' ? applyMiddleware(
    thunk) : composeWithDevTools(applyMiddleware(thunk));

export const store = createStore(
    reducers,
    enhancers,
);