import { logoutUserAsync } from '../store/profile';
import { useDispatch } from 'react-redux';
import { Container, Wrapper } from '../styled/components/Pages/Home';
import { useEffect } from 'react';
import { HomeHeader } from '../components/Headers/HomeHeader';
import {
  getClientsAsync,
  getClientUploadsAsync,
  getInactiveClientsAsync,
  setClientAsync,
  setVisibleUploadPopup,
} from '../store/clients';
import { Map } from '../components/Maps/Map';
import { ClientDashboard } from '../components/Dashboards/ClientDashboard';
import { WelcomePopup } from '../components/Popups/WelcomePopup';
import { ToPayPopup } from '../components/Popups/ToPayPopup';
import { UploadsPopup } from '../components/Popups/UploadsPopup';
import { deleteOverlayPopup } from '../store/maps';
import { getPostcode } from '../store/postcodes';
import { deleteCategoryPopup, getCategoriesAsync } from '../store/categories';
import { getGroupsByClientsAsync } from '../store/groups';
import { UploadErrorPopup } from "../components/Popups/UploadErrorPopup";
import { useParams } from 'react-router-dom';
export function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    const batch_ids = localStorage.getItem('batch_ids');
    dispatch(getInactiveClientsAsync());
    dispatch(getClientUploadsAsync());
    dispatch(getCategoriesAsync());
    dispatch(getGroupsByClientsAsync());
    dispatch(getClientsAsync(batch_ids))
  }, [dispatch]);

  const handleCreateClients = async (clients) => dispatch(
    setClientAsync(clients));

  const handleLogoutUser = () => dispatch(logoutUserAsync());

  const handleClosePopups = () => {
    dispatch(deleteOverlayPopup());
    dispatch(deleteCategoryPopup());
    dispatch(setVisibleUploadPopup(false));
    dispatch(getPostcode(null));
  };

  const { page } = useParams();
  return (
    <Container>
      <HomeHeader
        onLogout={handleLogoutUser}
        onCreateClients={handleCreateClients}
      />
      <Wrapper>
        {page !== 'setting' && (
          <WelcomePopup />
        )}
        <ToPayPopup />
        <UploadErrorPopup />
        <Map
          onClosePopups={handleClosePopups}
        />
        <UploadsPopup
          onClosePopups={handleClosePopups}
        />
        <ClientDashboard
          onCreateClients={handleCreateClients}
          onClosePopups={handleClosePopups} />
      </Wrapper>
    </Container>
  );
}
