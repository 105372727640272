import styled from 'styled-components';
import {Checkbox, Button} from 'antd';
import {FileOutlined} from '@ant-design/icons';

export const Wrapper = styled.div`
  background-color: #fff;
  padding: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;

  @media (max-width: 800px) {
    padding: 5px;
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftUploadWarning = styled.div`
  font-size: 18px;
  margin-right: 15px;
  
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 5px;

  & label {
    margin: 5px 0;
    padding: 0;
  }

  & .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 5px 0;
    padding: 0;
  }
`;

export const Btn = styled(Button)`
  width: 100%;
`;

export const FileIcon = styled(FileOutlined)`
  font-size: 34px;
`;

export const TitleData = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
`;

export const UploadDropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UploadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;