import styled from 'styled-components';

export const Paginate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
`;

export const Page = styled.span`
  cursor: pointer;
  margin: 0 5px;
  padding: 10px;
`;

export const ActivePage = styled.span`
  cursor: pointer;
  background-color: #5BA0F1;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 5px;
`;
