import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {Modal} from 'antd';
import {ErrorUploadClients} from '../Messages/ErrorUploadClients';
import {SuccessUploadClients} from '../Messages/SuccessUploadClients';
import {
  deleteInactiveClientsAsync,
  getClientsNotifications,
} from '../../store/clients';
import {
  NotificationsIcon,
  NotificationsStatistic,
} from '../../styled/components/Pages/Home';
import {setVisibleToPayPopup} from "../../store/profile";

export const ClientsNotifications = () => {
  const inactiveClients = useSelector(state => state.clients.inactiveClients);
  const notifications = useSelector(state => state.clients.notifications);
  const visibleToPayPopup = useSelector(state => state.profile.profile.to_pay_popup);
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch();

  const handleShowNotifications = () => {
    if (visibleToPayPopup) {
      return dispatch(setVisibleToPayPopup(true))
    }

    if (inactiveClients.length) {
      return Modal.error({
        centered: true,
        visible,
        width: 600,
        onCancel: () => setVisible(false),
        onOk: () => {
          dispatch(deleteInactiveClientsAsync());
          setVisible(true)
        },
        title: 'Clients whose postcode no longer exists.',
        content: <ErrorUploadClients inactiveClients={inactiveClients}/>,
      });
    }

    return Modal.success({
      centered: true,
      visible,
      width: 400,
      onCancel: () => setVisible(false),
      onOk: () => {
        dispatch(getClientsNotifications([]));
        setVisible(true)
      },
      content: <SuccessUploadClients notifications={notifications}/>,
    });
  }

  const numberNotifications = inactiveClients.length
    ? inactiveClients.length
    : notifications.length;

  return (
    <div
      onClick={handleShowNotifications}
    >
      <NotificationsIcon
        animation={numberNotifications}
        className="far fa-envelope"
      />
      <NotificationsStatistic
        animation={numberNotifications}
      >
        {numberNotifications}
      </NotificationsStatistic>
    </div>
  );
};
