import {useEffect} from 'react';
import VectorSource from 'ol/source/Vector';
import {default as OlVectorLayer} from 'ol/layer/Vector';
import {ClientsMarkerList} from '../Lists/ClientsMarkerList';
import {useDispatch, useSelector} from 'react-redux';
import {setSource} from '../../store/maps';

export function VectorLayer() {
  const map = useSelector(state => state.maps.map);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map)
      return null;

    const source = new VectorSource({
      features: null,
    });
    const layer = new OlVectorLayer({
      source,
      name: 'VectorLayer',
      layer:true,
      zIndex: 3,
    });

    map.addLayer(layer);
    dispatch(setSource(source));
  }, [map, dispatch]);

  return <ClientsMarkerList/>;
}