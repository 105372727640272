import styled from 'styled-components';
import {CloseOutlined} from '@ant-design/icons';

export const CloseBtn = styled(CloseOutlined)`
  opacity: .3;
  cursor: pointer;
  &:hover{
    opacity: 1;
    transition: .3s ease;
  }
`;

export const WrapperBtn = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
`;