import {groupsActionType} from './actions';

const initialState = {
  groupsByPolygon: [],
  groupsByClients: [],
  selectedPolygon: null, 
};

export const groupsReducer = (state = initialState, action) => {
  switch (action.type) {

    case groupsActionType.GET_GROUPS_BY_POLYGON:
      return {...state, groupsByPolygon: action.payload};

    case groupsActionType.GET_GROUPS_BY_CLIENTS:
      return {...state, groupsByClients: action.payload};
    
    case groupsActionType.SET_SELECTED_POLYGON:
      return { ...state, selectedPolygon: action.payload };
    default:
      return state;
  }
};