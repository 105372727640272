import {store} from '../../store';

export const addDataRadarChart = (clients, categories) => {
  const chart = store.getState().charts.radarChart;

  if ( !chart || !clients.length || !categories.length )
    return null;

  const groups = clients.map(client => client.group)
      .filter((item, index, array) => array.indexOf(item) === index);

  chart.data.labels = categories.filter(item => groups.includes(item.group))
      .map(category => category.name);
  chart.update();

  const allTotalOrders = clients
      .map(client => Object.entries(client)
          .filter(([key]) => key.includes('year'))
          .map(([key, value]) => Number(value)))
      .map(orders => orders.reduce((acc, order) => acc + order));

  const geoClients = clients.map((client, index) => ({
    ...client,
    geo: categories.find(category => category.group === client.group)?.name,
    total: allTotalOrders[index],
  })).sort((a, b) => b.total - a.total);

  chart.data.datasets.forEach(dataset => {
    const order = Array(chart.data.labels.length).fill(0);
    const firstGroup = Math.ceil((allTotalOrders.length * 25) / 100);
    const secondGroup = Math.ceil((allTotalOrders.length * 50) / 100);
    const thirdGroup = Math.ceil((allTotalOrders.length * 75) / 100);
    const label = dataset.label.toLowerCase();

    geoClients.forEach((item, index) => {
      if (index < firstGroup && label === 'platinum') {
        chart.data.labels.forEach((label, indexOrder) => {
          if (label === item.geo) {
            order[indexOrder] += 1;
          }
        });
      }

      if (index < secondGroup && index >= firstGroup && label === 'gold') {
        chart.data.labels.forEach((label, indexOrder) => {
          if (label === item.geo) {
            order[indexOrder] += 1;
          }
        });
      }

      if (index < thirdGroup && index >= secondGroup && label === 'silver') {
        chart.data.labels.forEach((label, indexOrder) => {
          if (label === item.geo) {
            order[indexOrder] += 1;
          }
        });
      }

      if (index >= thirdGroup && label === 'bronze') {
        chart.data.labels.forEach((label, indexOrder) => {
          if (label === item.geo) {
            order[indexOrder] += 1;
          }
        });
      }
    });

    dataset.data = order;
  });

  chart.update();
};