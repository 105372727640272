const company = (company) => company
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^\w-]+/g, '')
    .replace(/-+/g, '_')
    .replace(/_+/g, '_')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .replace(/^[_= ]*/, '');

const numberPeople = (number) => number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const validPassword = (password) => password.match(
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,30}$/);

export const regex = {
  company,
  numberPeople,
  validPassword
};