import apiClient from '../../libs/apiClient';
import {message} from 'antd';
import {setLoading} from "../../store/loading";
export const groupsActionType = {
  GET_GROUPS_BY_POLYGON:'GET_GROUPS_BY_POLYGON',
  GET_GROUPS_BY_CLIENTS:'GET_GROUPS_BY_CLIENTS',
  SET_SELECTED_POLYGON: "SET_SELECTED_POLYGON",
}

export const getGroupsByPolygon = (payload) =>({
  type: groupsActionType.GET_GROUPS_BY_POLYGON,
  payload
})

export const getGroupsByClients = (payload) =>({
  type: groupsActionType.GET_GROUPS_BY_CLIENTS,
  payload
})

export const getGroupsByCodeAsync = (polygon) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    dispatch(setSelectedPolygon(polygon));
    const {data: response} = await apiClient.get(`groups?polygon=${polygon}`);

    return dispatch(getGroupsByPolygon(response));
  } catch (e) {
    message.error('Internal server error.');
  }finally{
    dispatch(setLoading(false));
  }
}

export const getGroupsByClientsAsync = (batches = '') => async dispatch =>{
  try {
    const query = batches ? '?batches=' : '';
    const {data: response} = await apiClient.get(`groups/clients${query}${batches}`);

    return dispatch(getGroupsByClients(response));
  } catch (e) {
    message.error('Internal server error.');
  }
}
//Save Selected Polygon data
export const setSelectedPolygon = (payload) => ({
  type: groupsActionType.SET_SELECTED_POLYGON,
  payload,
});

export const doSaveArea = ({values} ) => async dispatch => {
  try{
    const {data: response} = await apiClient.post('saved-areas',values );
    return response;
  } catch (error) {
    if (error.response?.data?.errors) {
        return { errors: error.response.data.errors }; 
    } else if (error.response?.data?.message) {
        return { error: error.response.data.message  }; 
    }
    return { error:  'Internal server error.'  }; 
    }
};