import {
  Form,
  Label,
  Input,
  Button,
} from '../../styled/components/Pages/ResetPassword';
import {useFormik} from 'formik';
import {TextError} from '../../styled/components/Pages/Login';
import {useQuery} from '../../hooks/useQuery';
import * as Yup from 'yup';
import {regex} from '../../helpers/regexs/regex';

export function ResetPasswordForm({onSubmit}) {
  const query = useQuery();

  const formik = useFormik({
    initialValues: {
      email: query.get('email'),
      token: query.get('token'),
      password: '',
      password_confirmation: '',
      success: false
    },
    validationSchema: Yup.object({
      password: Yup.string()
          .test('Password validation',
              'Your password must be at least 8 characters long, be of mixed case and also contain a digit or symbol.',
              (password) => {
                return !!regex.validPassword(password);
              })
          .max(30, 'Must be 30 characters or less')
          .min(8, 'Must be at least 8 characters long.')
          .required('Required'),
      password_confirmation: Yup.string().when('password', {
        is: val => (val && val.length > 0),
        then: Yup.string().oneOf(
            [Yup.ref('password')],
            'Both password need to be the same',
        ),
      }).required('Required'),
    }),
    onSubmit: values => {
      onSubmit(values);
    },
  });

  const passwordValidationError = formik.touched.password &&
  formik.errors.password ? (
      <TextError>{formik.errors.password}</TextError>
  ) : null;

  const confirmPasswordValidationError = formik.touched.password_confirmation &&
  formik.errors.password_confirmation ? (
      <TextError>{formik.errors.password_confirmation}</TextError>
  ) : null;

  return (
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Label htmlFor="password ">Reset
          Password</Label>
        <Input placeholder="Password" id="password"
               value={formik.values.password}
               onChange={formik.handleChange}
               type="password"/>
        {passwordValidationError}
        <Input placeholder="Confirm password" id="password_confirmation"
               value={formik.values.password_confirmation}
               onChange={formik.handleChange}
               type="password"/>
        {confirmPasswordValidationError}
        <Button type="submit">Save New Password</Button>
      </Form>
  );
}
