import { useDispatch, useSelector } from "react-redux";
import { setVisibleToSaveAreaPopup } from "../../store/profile";
import { useState, useEffect } from "react";
import { message, Space, Input } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Form, ModalForm } from "../../styled/components/Forms/ClientsCreateForm";
import { ValidationText } from "../../styled/landing/plansRegisterModal.styles";
import { doSaveArea } from '../../store/groups';
export const SavedAreaPopup = () => {
  const dispatch = useDispatch();
  const visibleToSaveAreaPopup = useSelector(
    (state) => state.profile.setVisibleToSaveAreaPopup
  );
  const selectedPolygon = useSelector((state) => state.groups.selectedPolygon);

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      visible: false,
      name: "",
      polygon_data: selectedPolygon || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Name is required.")
        .min(3, "Name must be at least 3 characters."),
      polygon_data: Yup.string().trim().required("First draw polygon area"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const response = await dispatch(doSaveArea({ values }));
        if (response?.errors) {
          setErrors(
            Object.fromEntries(
              Object.entries(response.errors).map(([field, messages]) => [field, messages[0]])
            )
          );
          return false;
        } else if (response?.error) {
          message.error(response?.error?.error || "Something went wrong. Please try again");
          return false;
        }
        message.success("Area saved successfully!");
        formik.resetForm();
        dispatch(setVisibleToSaveAreaPopup(false))

      } catch (err) {
        console.error("Error submitting form:", err);
        message.error("Something went wrong. Please try again");

      } finally {
        setStatus({ success: false });
        setSubmitting(false);
        setLoading(false);
      }

    },
  });


  useEffect(() => {
    if (visibleToSaveAreaPopup) {
      formik.resetForm();
    }
    formik.setFieldValue("visible", visibleToSaveAreaPopup);

  }, [visibleToSaveAreaPopup]);

  useEffect(() => {
    formik.setFieldValue("polygon_data", selectedPolygon || '');
    formik.validateField("polygon_data");
  }, [selectedPolygon]);



  return (
    <>
      {visibleToSaveAreaPopup && (
        <ModalForm
          visible={formik.values.visible}
          title={"Area Save"}
          okText={loading ? "Saving..." : "Save"}
          okButtonProps={{ disabled: loading, loading: loading }}
          cancelButtonProps={{ type: "danger", disabled: loading }}
          onOk={formik.handleSubmit}
          onCancel={() => dispatch(setVisibleToSaveAreaPopup(false))}
          centered={true}
        >
          <Form>

            <Space direction="vertical" style={{ width: '100%' }}>
              <label htmlFor="name">Enter a Name to Save the Filter Polygon</label>
              <Input
                name="name"
                placeholder="Enter a Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <ValidationText>{formik.errors.name}</ValidationText>
              ) : null}
            </Space>

            <Space direction="vertical" style={{ width: '100%', marginTop: 15 }}>

              {formik.touched.polygon_data && formik.errors.polygon_data ? (
                <ValidationText>{formik.errors.polygon_data}</ValidationText>
              ) : null}
            </Space>

          </Form>
        </ModalForm>
      )}
    </>
  );
};
