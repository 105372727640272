import styled from 'styled-components';
import {Select as AntdSelect} from 'antd';
import {geoptimiseColor} from '../Variables/variables';

export const Container = styled.div`
  display: ${({visible})=>visible ? 'block' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Modal = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #3B3F40;
  left: 0;
  top: 0;
  opacity: 0.8;
`;

export const Form = styled.form`
  opacity: 1;
  position: absolute;
  top: 30%;
  left: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 20px 40px;
`;

export const Label = styled.label`
  margin-bottom: 10px;
  color: ${geoptimiseColor};
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  text-align: left;
  overflow: hidden;
`;

export const Input = styled.input`
  outline: none;
  display: block;
  width: 300px;
  height: 30px;
  border: 1px solid #d9d9d9;
  margin: 0 0 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  font-weight: 400;
  overflow: hidden;

  &:focus {
    outline: 1px solid ${geoptimiseColor};
  }

  &:active {
    outline: 1px solid ${geoptimiseColor};
  }
`;

export const Btn = styled.button`
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
  width: 240px;
  height: 40px;
  padding: 10px 15px;
  color: ${geoptimiseColor};
  font-size: 16px;
  font-weight: bold;
  border: 1px solid ${geoptimiseColor};
  &:hover {
    background: ${geoptimiseColor};
    color: #fff;
    transition: 1s ease;
  }
`;

export const Toggle = styled.div`
  width: 100%;
`;

export const ToggleLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  border: 5px solid ${geoptimiseColor};
`;

export const ToggleRole = styled.span`
  cursor: pointer;
  position: absolute;
  text-transform: capitalize;
  top: 0;
  right: 0;
  width: 70px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${geoptimiseColor};
  color: #fff;
`;

export const RegexText = styled.span`
  width: 300px;
  text-align: left;
  color: ${geoptimiseColor};
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Select = styled(AntdSelect)`
  width: 100%;
  margin: 0 0 10px 0;
`;