import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { message, Spin, Space } from 'antd';
import { getTwoFactorAuthSettingAsync } from '../../store/settings';
import apiClient from '../../libs/apiClient';
import './TwoFactor.css';
import { PopupDescription, PopupTitle } from "../../styled/components/Popups/HelpPopup";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SpinnerBox, ValidationText } from "../../styled/landing/plansRegisterModal.styles";
import { SafetyOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getProfileAsync } from '../../store/profile';
import { FaRegCopy } from "react-icons/fa";
export function TwoFactorAuth({ handleClose }) {
  const { setting_two_factor: settings } = useSelector(state => state.settings);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [settingData, setSettingData] = useState(settings);

  const [qrCodeText, setQrCodeText] = useState("");
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(qrCodeText).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };
  const history = useHistory();
  const closeModal = () => {
    handleClose();
    history.push("/dashboard");
  };
  useEffect(() => {
    dispatch(getTwoFactorAuthSettingAsync());
  }, [dispatch]);

  useEffect(() => {
    //setSettingData(settings);
    if (settings?.showQrCode) {
      handleGenerateQRCode();
    } else {
      setSettingData(settings);
    }
  }, [settings]);


  const handleEnable2FA = async () => {
    try {
      setLoading(true);
      const { data: response } = await apiClient.post('auth/2fa/enable');
      if (response?.data?.showQrCode) {
        setSettingData(response?.data);
        handleGenerateQRCode();
      }
    } catch (err) {
      if (err?.response) {
        const errorMessage = err?.response?.data?.message || "Failed to enable 2FA.";
        message.error(errorMessage);
      } else if (err.request) {
        message.error("No response from server. Please check your internet connection.");
      } else {
        message.error("Failed to enable 2FA.");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleGenerateQRCode = async () => {
    try {
      setLoading(true);
      const qrCodeResponse = await apiClient.post('auth/2fa/generate-qr');
      if (qrCodeResponse?.data?.data) {
        setSettingData(qrCodeResponse?.data?.data);
        setQrCodeText(qrCodeResponse?.data?.data?.code);
      }
    } catch (err) {
      //message.error('Failed to generate QR Code for 2FA.');
      if (err?.response) {
        const errorMessage = err?.response?.data?.message || "Failed to generate QR Code for 2FA.";
        message.error(errorMessage);
      } else if (err.request) {
        message.error("No response from server. Please check your internet connection.");

      } else {
        message.error("Failed to generate QR Code for 2FA.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDisable2FA = async () => {
    try {
      setLoading(true);
      const response = await apiClient.post('auth/2fa/disable');
      if (response?.data?.data) {
        setSettingData(response?.data?.data);
      }
      message.success(response?.data?.message || '2FA successfully disabled.');
    } catch (err) {
      if (err?.response) {
        const errorMessage = err?.response?.data?.message || 'Failed to disable 2FA.';
        message.error(errorMessage);
      } else if (err.request) {
        message.error("No response from server. Please check your internet connection.");

      } else {
        message.error('Failed to disable 2FA.');
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .matches(/^\d+$/, "Only numbers are allowed")
        .length(6, "Code must be exactly 6 digits")
        .required("Code is required"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const response = await apiClient.post('auth/2fa/verify', { "code": values.code });
        if (response?.data?.status) {
          setSettingData(response?.data?.data);
          message.success(response?.data?.message || 'Two-Factor Authentication enabled & verified successfully.');
          await dispatch(getProfileAsync());
        } else {
          message.error(response?.data?.message || 'Something went wrong. Please try again.');
          setErrors({ code: response?.data?.message || "Something went wrong. Please try again." });
        }

        setStatus({ success: false });
        setSubmitting(false);
      } catch (err) {
        if (err?.response) {
          const errorMessage = err?.response?.data?.message || "Server error occurred. Please try again.";
          message.error(errorMessage);
          setErrors({ code: errorMessage });
        } else if (err.request) {
          message.error("No response from server. Please check your internet connection.");
          setErrors({ code: "No response from server. Please check your internet connection." });
        } else {
          message.error("Something went wrong. Please try again.");
          setErrors({ code: err.message || "Something went wrong. Please try again." });
        }
      } finally {
        setStatus({ success: false });
        setSubmitting(false);
      }
    },
  });


  return (
    <div className="card">

      <div className="card-body">
        {(settingData.length === 0) && (
          <div>
            <SpinnerBox><Spin /></SpinnerBox>
          </div>
        )}
        {settingData && settingData.showEnableButton && (
          <div className="fa-auth-container">
            <button
              onClick={handleEnable2FA}
              disabled={loading}
              className="form-button btn-success btn-w"
            >
              {loading ? 'Enabling...' : 'Enable Two-Factor Authentication'}
            </button>
          </div>
        )}
        {settingData && settingData?.qr_code && (
          <div className="fa-auth-container">
            <h3>Please finish configuring Two-Factor authentication below.</h3>
            <PopupDescription>
              When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You
              may retrieve this token from your phone&apos;s Authenticator application.
            </PopupDescription>

            <PopupDescription>
              To finish enabling two factor authentication, scan the following QR code using your phone&apos;s authenticator
              application or enter the setup key and provide the generated OTP code.

            </PopupDescription>
            <div className="qr-code-container">
              <div className="image" dangerouslySetInnerHTML={{ __html: settingData?.qr_code }} />

            </div>
            <div className="qr-code-container">
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <input type="text" value={qrCodeText} readOnly style={{ padding: "5px" }} />
                <FaRegCopy
                  onClick={handleCopy}
                  style={{ cursor: "pointer", fontSize: "20px", color: copied ? "green" : "black" }}
                  title="Copy to clipboard"
                />
                {copied && <span style={{ color: "green" }}>Copied!</span>}
              </div>
            </div>

            <form onSubmit={formik.handleSubmit} noValidate className='form-fa'>
              <Space direction="vertical" style={{ width: '100%' }}>
                <label htmlFor="auth-code">Two-Factor Authentication Auth Code</label>
                <input
                  className="textbox"
                  id="auth-code"
                  type="text"
                  name="code"
                  value={formik.values.code}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    let value = e.target.value.replace(/\D/g, "");
                    if (value.length > 6) value = value.slice(0, 6);
                    formik.setFieldValue("code", value);
                  }}
                  placeholder="Enter 2FA Code"
                />
                {formik.touched.code && formik.errors.code && (
                  <ValidationText>{formik.errors.code}</ValidationText>
                )}

              </Space>

              <div>
                <button type="submit" className="form-button btn-success" disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? "Verifying..." : "Verify"}
                </button>
              </div>
            </form>
          </div>

        )}
        {settingData && settingData.showDisableButton && (
          <div className="fa-auth-container">
            <PopupDescription style={{ display: "flex", alignItems: "center" }}>
              <SafetyOutlined style={{ color: "#00e109", marginRight: "8px", fontSize: "50px" }} />
              You have successfully enabled two-factor authentication (2FA), adding an extra layer of security to your account.
            </PopupDescription>
            <button
              type="button"
              className="form-button btn-success btn-w"
              onClick={closeModal}
            >
              Close
            </button>
            {/* <button
              onClick={handleDisable2FA}
              disabled={loading}
              className="form-button btn-danger btn-w"
            >
              {loading ? 'Disabling...' : 'Disable Two-Factor Authentication'}
            </button> */}
          </div>
        )}
      </div>
    </div>

  );
}
