import {
  BtnControl,
  IconControl,
} from '../../styled/components/Controls/MapControl';
import {setZoom} from '../../store/maps';
import {useDispatch, useSelector} from 'react-redux';

export const DecreaseZoomBtn = () => {
  const map = useSelector(state => state.maps.map);
  const dispatch = useDispatch();

  const handleDecreaseZoom = () => {
    const view = map.getView();
    const zoom = view.getZoom();

    dispatch(setZoom(zoom - 1));

    return view.setZoom(zoom - 1);
  };

  return (
      <BtnControl title='Zoom out' onClick={handleDecreaseZoom}>
        <IconControl className="fas fa-minus"/>
      </BtnControl>
  );
};
