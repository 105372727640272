import {
  Rhombus,
  SpinnerWrapper,
} from '../../styled/components/Spinners/RhombusesSpinner';

export const RhombusesSpinner = ({onClick}) => {
  return (
        <SpinnerWrapper  onClick={onClick}>
          <Rhombus />
          <Rhombus />
          <Rhombus />
        </SpinnerWrapper>
  );
};