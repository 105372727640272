import React from 'react';
import {motion} from "framer-motion/dist/framer-motion";
import contactBg from "../../assets/img/contact-video-poster.png";
import {imageAnimation, textFadeTopAnimation, transitionEffect} from "../../helpers/animations/landingAnimations";
import videoMain from "../../assets/video/bottom-video.mp4";

function Contact() {
    return (
        <section className="bg">
            <div className="container">
                <div className="main-cta " style={{overflow: 'hidden'}}>
                    <div className="main-cta__video">
                        <video autoPlay loop muted poster={contactBg}>
                            <source src={videoMain}/>
                        </video>
                    </div>
                    <motion.div style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}
                                initial={imageAnimation.initial}
                                whileInView={imageAnimation.inView}
                                viewport={{once: true}}></motion.div>
                    <span></span>
                    <motion.h2 initial={textFadeTopAnimation.initial}
                               whileInView={textFadeTopAnimation.inView}
                               viewport={{once: true}}
                               transition={transitionEffect.transition} className="">Get ahead of your competitors.
                    </motion.h2>
                    <motion.a initial={textFadeTopAnimation.initial}
                              whileInView={textFadeTopAnimation.inView}
                              viewport={{once: true}}
                              transition={transitionEffect.transition}
                              href="https://www.innovation-mapping.com/contact-innovation-mapping/" target="_blank"
                              rel="noopener noreferrer" className="btn btn--sml">Contact us
                    </motion.a>
                </div>
            </div>
        </section>
    );
}

export default Contact;
