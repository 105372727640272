import {
    PopupDescription,
    PopupTitle,
    PopupWrapper,
    BuildPeriodsImage,
    Image,
    Table
} from '../../styled/components/Popups/OverlayPopup';
import {useSelector} from 'react-redux';
import averageHousehold from "../../assets/img/average-household.png";
import buildPeriods from "../../assets/img/build-periods.png";
import consumerVulnerability from "../../assets/img/consumer-vulnerability.png";
import internetUserClassification from "../../assets/img/internet-user-classification.png";


export const OverlayPopup = ({onClosePopups}) => {
  const {visible, name} = useSelector(state => state.maps.overlayPopup);

  if (!visible)
    return null;

  return <PopupWrapper>
    <PopupTitle>
      {name}
      <i className="fas fa-times" onClick={onClosePopups}/>
    </PopupTitle>
    <PopupDescription>

        {name === 'Postcode sector boundaries' &&
            <>
                <p>
                    This layer shows the boundaries for each Postcode Sector<br/>
                    Postcodes are structured as follows:<br/>
                </p>
                <Table>
                    <thead>
                        <tr>
                            <td>
                                Outward Code
                            </td>
                            <td>
                                Inward Code
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>PO15</td>
                            <td>5RR</td>
                        </tr>
                    </tbody>
                </Table>
                <Table>
                    <thead>
                    <tr>
                        <td>
                            Example
                        </td>
                        <td>
                            Geographic Unit
                        </td>
                        <td>
                            Number in UK
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>PO</td>
                        <td>Postcode Area</td>
                        <td>124</td>
                    </tr>
                    <tr>
                        <td>PO15</td>
                        <td>Postcode District</td>
                        <td>3,114</td>
                    </tr>
                    </tbody>
                </Table>
                <Table>
                    <thead>
                    <tr>
                        <td>
                            PO15 5
                        </td>
                        <td>
                            Postcode Sector
                        </td>
                        <td>
                            12,381
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>PO15 5RR (Live)</td>
                        <td>Unit Postcode</td>
                        <td>Approximately 1.75 million</td>
                    </tr>
                    </tbody>
                </Table>
                <p>
                    Source: Office for National Statistics
                    <a  href="https://www.ons.gov.uk/" target="_blank"> https://www.ons.gov.uk/</a>
                </p>

            </>
        }
        { name === 'Consumer Clusters' &&
            <>
                <p>
                    This layer is a hierarchical geodemographic classification across the UK which identifies areas of the
                    country with similar characteristics. The classifications are called clusters and are derived using census data,
                    and displayed at Output Area (OA) level. Each OA contains approximately 125 households.
                </p>
                <p>
                    Source: Office for National Statistics
                    <a
                        href="https://www.ons.gov.uk/"
                        target="_blank"
                    > https://www.ons.gov.uk/
                    </a>
                </p>
            </>
        }
        {name === 'Average weekly household expenditure' &&
            <>
                <p>
                    Average weekly household expenditure on goods and services in the UK (GBP) at Output Area (OA) level.
                    Each OA contains approximately 125 households.
                </p>
                <Image src={averageHousehold} alt="Average Household"/>
                <p>
                    Source: Office for National Statistics
                    <a
                        href="https://www.ons.gov.uk/"
                        target="_blank"
                    > https://www.ons.gov.uk/
                    </a>
                </p>
            </>
        }
        {name === 'Modal Property Age' &&
           <>
            <p>
                This layer shows the most frequent build period for houses in a given area. Areas are Lower-level Super Output Area (LSOA).
                Each LSOA contains between 400 and 1,200 households.
            </p>
            <p>
                Build periods are as follows:
            </p>
               <BuildPeriodsImage src={buildPeriods} alt="Build Periods"/>
           <p>
               Source: Valuation Office Agency <br/>
               <a
                   href="https://www.gov.uk/government/organisations/valuation-office-agency"
                   target="_blank"
               >
                   https://www.gov.uk/government/organisations/valuation-office-agency
               </a>
           </p>
           </>
        }
        {name === 'Consumer Vulnerability' &&
            <>
                <p>
                    Some consumers are more vulnerable to marketing practices due to their personal traits such as age,
                    health or household makeup. This vulnerability varies by product and industry. Consumer vulnerability here is defined
                    as the risk that a consumer’s mental, physical or financial welfare may be damaged when engaging in a market interaction.
                    This map layer is displayed at Output Area (OA) level. Each OA contains approximately 125 households.
                </p>
                <Image src={consumerVulnerability} alt="Consumer Vulnerability"/>
                <p>
                    Source: Consumer Data Research Centre
                    <a
                        href="https://www.cdrc.ac.uk/"
                        target="_blank"
                    > https://www.cdrc.ac.uk/
                    </a>
                </p>
            </>
        }
        {name === 'Internet User Classification' &&
            <>
                <p>
                    The IUC is a bespoke classification that describes how people living in different parts of Great Britain interact with
                    the Internet. It provides aggregate population profiles of Internet use and engagement at Lower-level Super Output Area
                    (LSOA). Each LSOA contains between 400 and 1,200 households. The classification is as follows:
                </p>
                <Image src={internetUserClassification} alt="Internet User Classification"/>
                <p>
                    Source: Consumer Data Research Centre
                    <a
                        href="https://www.cdrc.ac.uk/"
                        target="_blank"
                    > https://www.cdrc.ac.uk/</a>
                </p>
            </>
        }
    </PopupDescription>
  </PopupWrapper>;
};
