import {
  ToggleLeft,
  ToggleRight,
  ToggleWrapper,
} from '../../styled/components/Pages/Home';
import {Link, useParams} from 'react-router-dom';
import {usePendingPayment} from "../../hooks/usePendingPayment";
import {setVisibleToPayPopup} from "../../store/profile";
import {useDispatch} from "react-redux";

export const HomeToggle = () => {
  const {page} = useParams()
  const pendingPayment = usePendingPayment()
  const dispatch = useDispatch();

  return (
    <ToggleWrapper>
      {pendingPayment
        ?
        <>
          <Link to="/map">
            <ToggleLeft
              toggleColor={page === 'map' || '' ? '#fff' : '#585858'}
              toggleBackground={page === 'map' || ''
                ? '#5BA0F1'
                : '#A0A0A0'}
            >
              Map
            </ToggleLeft>
          </Link>
          <div onClick={() => dispatch(setVisibleToPayPopup(true))}>
            <ToggleRight
              toggleColor={page === 'dashboard' ? '#fff' : '#585858'}
              toggleBackground={page === 'dashboard'
                ? '#5BA0F1'
                : '#A0A0A0'}
            >
              Dashboard
            </ToggleRight>
          </div>
        </>
        :
        <>
          <Link to="/map">
            <ToggleLeft
              toggleColor={page === 'map' || '' ? '#fff' : '#585858'}
              toggleBackground={page === 'map' || ''
                ? '#5BA0F1'
                : '#A0A0A0'}
            >
              Map
            </ToggleLeft>
          </Link>
          <Link to="/dashboard">
            <ToggleRight
              toggleColor={page === 'dashboard' ? '#fff' : '#585858'}
              toggleBackground={page === 'dashboard'
                ? '#5BA0F1'
                : '#A0A0A0'}
            >
              Dashboard
            </ToggleRight>
          </Link>
        </>
      }
    </ToggleWrapper>
  );
};
