import {usersActionType} from './actions';

const initialState = {
  users: [],
  plans: [],
  statuses: [],
  usersByCompany: [],
  pagination: {
    total: null,
    perPage: null,
    currentPage: 1,
    pages: null,
  },
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {

    case usersActionType.GET_USERS:
      return {
        ...state, users: action.payload.data, pagination: {
          total: action.payload.meta.total,
          currentPage: action.payload.meta.current_page,
          perPage: action.payload.meta.per_page,
          pages: Array(Math.ceil(
              action.payload.meta.total / action.payload.meta.per_page))
              .fill(1).map((page, index) => page + index),
        },
      };

    case usersActionType.GET_PLANS:
      return {...state, plans: action.payload};

    case usersActionType.GET_STATUSES:
      return {...state, statuses: action.payload};

    case usersActionType.GET_USERS_BY_COMPANY:
      return {...state, usersByCompany: action.payload};

    case usersActionType.SET_USER:
      return {
        ...state,
        users: state.users.length >= state.pagination.perPage
            ? [
              action.payload, ...state.users
                  .filter(
                      user => user.id > state.users[state.users.length - 1].id)]
            : [action.payload, ...state.users],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
          pages: Array(Math.ceil(
              (state.pagination.total + 1) / state.pagination.perPage))
              .fill(1).map((page, index) => page + index),
        },
      };

    case usersActionType.UPDATE_USER:
      return {
        ...state,
        users: state.users.map(user => {
          if (action.payload.id === user.id) {
            return action.payload;
          }
          return user;
        }),
      };

    default:
      return state;
  }
};
