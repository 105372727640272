import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TileLayer from 'ol/layer/Tile';
import {BingMaps} from 'ol/source';

export function AerialLayer() {
  const map = useSelector(state => state.maps.map);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map)
      return null;

    const layer = new TileLayer({
      visible: false, source: new BingMaps({
        key: process.env.REACT_APP_BING_MAPS_KEY,
        imagerySet: 'Aerial',
      }),
      name: 'Aerial',
      layer:true
    });

    map.addLayer(layer);
  }, [map, dispatch]);

  return null;
}