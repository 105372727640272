import styled from 'styled-components';

export const Container = styled.div`
  background: #FFF;
  padding: 15px;
  display: flex;
  margin-bottom: 25px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    left: 49%;
    bottom: -20px;
    border: 20px solid transparent;
    border-left: 20px solid #fff;
    border-radius: 30px 0 30px 0;
    transform: rotate(180deg);
  }
`;

export const CloseIcon = styled.i`
  &:hover {
    opacity: .7;
    cursor: pointer;
  }
`;

export const TextPopup = styled.p`
  margin: 0 10px 0 0;
  font-size: 16px;
  width: 200px;
`;

export const CoordinateText = styled.p`
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
`;