import React from 'react';
import {motion} from "framer-motion/dist/framer-motion";
import { textFadeTopAnimation, transitionEffect} from "../../helpers/animations/landingAnimations";
import {demographicsItemsContents} from "../../helpers/landing/demographicsItemsContents";
import DemographicsItem from "../../components/Landing/DemographicsItem";

function Demographics() {
    return (
        <section className='demographics'>
            <div className="container">
                <motion.h3 initial={textFadeTopAnimation.initial}
                           whileInView={textFadeTopAnimation.inView}
                           viewport={{once: true}}
                           transition={transitionEffect.transition} className="center ">Filter customers by demographics
                </motion.h3>
                <ul className="icon-list">
                    {
                        demographicsItemsContents.map((item, index) => (
                            <DemographicsItem key={index} imgSrc={item.img} delay={item.delay}>
                                {item.text}
                            </DemographicsItem>
                        ))
                    }
                </ul>
            </div>
        </section>
    );
}

export default Demographics;
