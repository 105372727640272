import Chart from 'chart.js/auto';
import {store} from '../../store';
import {createMapLayer} from '../layers/createGeoLayer';
import {setCategoryPopup} from '../../store/categories';
import {updateFeaturesStyle} from '../features/updateFeature';
import {fromLonLat} from 'ol/proj';
import {createCustomerPopup} from '../popup/createCustomerPopup';

export const createGeoPieChart = (chartClassName,history) => {
  const ctx = document.getElementById(chartClassName)
      .getContext('2d');

  return new Chart(ctx, {
    type: 'doughnut',
    data: {
      labels: [
        'Farming Communities',
        'Rural Tenants',
        'Ageing Rural Dwellers',
        'Students Around Campus',
        'Inner-City Students',
        'Comfortable Cosmopolitans'],
      datasets: [
        {
          data: [236, 365, 112, 234, 783, 533],
          backgroundColor: [
            '#398237',
            '#72af70',
            '#aadba9',
            '#8a0f11',
            '#ae4142',
            '#d17274'],
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          onHover: (event, legendItem, legend) => {
            event.native.target.style.cursor = 'pointer';
            legend.chart.config.data.datasets[0].backgroundColor = legend.chart.config.data.datasets[0].backgroundColor.map(
                (color, index) => {
                  if (index === legendItem.index) {
                    return color;
                  }

                  return color + '40';
                });
            legend.chart.update();
          },
          onLeave: (event, legendItem, legend) => {
            event.native.target.style.cursor = 'default';
            legend.chart.config.data.datasets[0].backgroundColor = legend.chart.config.data.datasets[0].backgroundColor.map(
                color => color.slice(0, 7));
            legend.chart.update();
          },
          position: 'right',
          labels: {
            pointStyle: 'circle',
            usePointStyle: true,
            generateLabels: (chart) => {
              const data = chart.data;
              return data.labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i);

                return {
                  text: `${label} (${chart.data.datasets[0].data[i]})`,
                  fillStyle: style.backgroundColor,
                  strokeStyle: style.borderColor,
                  lineWidth: style.borderWidth,
                  hidden: !chart.getDataVisibility(i),
                  padding: '20px',
                  index: i,
                };
              });
            },
          },
          onClick: (event, legendItem, legend) => {
            const map = store.getState().maps.map;
            const categories = store.getState().categories.categories;
            const clients = store.getState().clients.clients;
            const legendColor = legend.chart.config.data.datasets[0].backgroundColor[legendItem.index];

            const gr = categories.find(item=>item.color===legendColor)
            const legendText = legend.chart.config.data.labels[legendItem.index];

            createMapLayer(map, gr, categories);
            console.log(categories)

            const category = categories.find(
                category => category.color === legendColor);

            const client = clients.find(
                client => client.group === category.group);

            store.dispatch(setCategoryPopup({
              name: category.name,
              code: client?.oa_sa || null,
              visible: true,
              description: category.description,
              color: category.color,
              group: category.group,
              customer: client?.customer_id || null,
              batchId: client?.batch_id || null,
            }));

            if (!client) {
              updateFeaturesStyle(map);
            } else {
              createCustomerPopup(client.customer_id);
              map.getView().setCenter(fromLonLat(client.location.coordinates));
              map.getView().setZoom(12);
            }

            history.push('/map');
          },
        },
      },
    },
  });
};
