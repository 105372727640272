import {Container} from '../styled/components/Pages/ResetPassword';
import {ResetPasswordForm} from '../components/Forms/ResetPasswordForm';
import {LoginFooter} from '../components/Footers/LoginFooter';
import {LoginHeader} from '../components/Headers/LoginHeader';
import {Api} from '../api';

export function ResetPassword() {

  const handleResetPassword = async (credentials) => await Api.Users.resetPassword(
      credentials);

  return (
      <Container>
        <LoginHeader/>
        <ResetPasswordForm onSubmit={handleResetPassword}/>
        <LoginFooter/>
      </Container>
  );
}