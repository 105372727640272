import styled from 'styled-components';
import {geoptimiseColor} from "../components/Variables/variables";

export const ModalInner = styled.div`
    h2, p {
        color: ${geoptimiseColor};
    }
`;

export const SpinnerBox = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

export const ValidationText = styled.div`
    color: #FF0000;
    margin-top: -10px;
    padding-bottom: 5px;
`;
