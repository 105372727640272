import {
  BtnControl,
  IconControl,
} from '../../styled/components/Controls/MapControl';
import {closePopup} from '../../helpers/popup/closePopup';
import {setLayersPopup} from '../../store/maps';
import {useDispatch} from 'react-redux';
import {setVisibleToPayPopup} from "../../store/profile";
import {usePendingPayment} from "../../hooks/usePendingPayment";

export const LayersPanelBtn = () => {
  const dispatch = useDispatch();
  const pendingPayment = usePendingPayment()

  const handleChangeLayers = () => {
    if (pendingPayment) {
      return dispatch(setVisibleToPayPopup(true))
    }

    closePopup();
    dispatch(setLayersPopup(true));
  };

  return (
      <BtnControl title='Map layers panel' onClick={handleChangeLayers}>
        <IconControl className="fas fa-layer-group"/>
      </BtnControl>
  );
};
