import React from 'react';
import {motion} from "framer-motion/dist/framer-motion"
import bg from "../../assets/img/pattern.svg";

function Preloader() {


    return (
        <motion.div className="se-pre-con" animate={{ opacity: [1, 1, 0] }} transition={{ease: "easeOut", duration: 2}}>
            <div className="pattern" style={{backgroundImage: `url(${bg})`}}></div>
            <div className="loader">
                <svg x="0px"
                     y="0px" viewBox="0 0 35.9 38.7">
                    <defs>
                    </defs>
                    <g>
                        <path d="M18,38.7c-0.6,0-1.1-0.2-1.5-0.6L2.6,24.2c-3.4-3.4-3.4-8.9,0-12.3l9.3-9.3c3.4-3.4,8.9-3.4,12.3,0l9,9
		c0,0,0.1,0.1,0.1,0.1l0.1,0.1c3.4,3.4,3.4,8.9,0,12.3c-0.8,0.8-2.2,0.8-3.1,0c-0.8-0.8-0.8-2.2,0-3.1c1.7-1.7,1.7-4.5,0-6.2
		l-0.1-0.1c0,0-0.1-0.1-0.1-0.1l0,0l-9-9c-1.7-1.7-4.5-1.7-6.2,0l-9.3,9.3c-1.7,1.7-1.7,4.5,0,6.2L18,33.5l5.7-5.7l-4.2-4.2
		c-0.8-0.8-0.8-2.2,0-3.1c0.8-0.8,2.2-0.8,3.1,0l5.8,5.8c0.8,0.8,0.8,2.2,0,3.1l-8.7,8.7C19.1,38.5,18.5,38.7,18,38.7z"/>
                    </g>
                </svg>

            </div>
        </motion.div>
    );
}

export default Preloader;
