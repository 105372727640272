export const geocoderActionType = {
  SET_GEOCODER: 'SET_GEOCODER',
  SET_GEOCODER_POPUP: 'SET_GEOCODER_POPUP',
};

export const setGeocoder = (payload) => ({
  type: geocoderActionType.SET_GEOCODER,
  payload,
});

export const setGeocoderPopup = (payload) => ({
  type: geocoderActionType.SET_GEOCODER_POPUP,
  payload,
});