import {chartsActionType} from './actions';

const initialState = {
  totalCustomersChart: null,
  customerSpendChart: null,
  customerPopupChart:null,
  customerGeoChart: null,
  radarChart: null,
  selectionGeoChart: null,
};

export const chartsReducer = (state = initialState, action) => {
  switch (action.type) {

    case chartsActionType.SET_TOTAL_CUSTOMERS_CHART:
      return {
        ...state,
        totalCustomersChart: action.payload,
      };

    case chartsActionType.SET_CUSTOMER_SPEND_CHART:
      return {
        ...state,
        customerSpendChart: action.payload,
      };

    case chartsActionType.SET_CUSTOMER_GEODEMOGRAPHIC_CHART:
      return {
        ...state,
        customerGeoChart: action.payload,
      };

    case chartsActionType.SET_RADAR_CHART:
      return {
        ...state,
        radarChart: action.payload,
      };

    case chartsActionType.SET_SELECTION_GEODEMOGRAPHIC_CHART:
      return {
        ...state,
        selectionGeoChart: action.payload,
      };

    case chartsActionType.SET_CUSTOMER_POPUP_CHART:
      return {
        ...state,
        customerPopupChart: action.payload,
      };

    default:
      return state;
  }
};
