import {
  Container, Main,
  ToggleForm,
  ToggleReset,
} from '../styled/components/Pages/Login';
import { LoginHeader } from '../components/Headers/LoginHeader';
import { LoginFooter } from '../components/Footers/LoginFooter';
import { useEffect, useState } from 'react';
import { LoginTwoFactorForm } from '../components/Forms/LoginTwoFactorForm';
import { Api } from '../api';
import { useHistory } from "react-router-dom";

export function CheckTwoFactorAuth() {
  const history = useHistory();

  const handleTwoFactorAuthVerify = async credentials => {
    const { isTwoFactorEnabled, redirect_url } = await Api.Users.verifyTwoFactorAuth(credentials);

    if (redirect_url) {
      if (redirect_url === 'admin/dashboard') {
        return window.location = `${redirect_url}`;
      }
      return window.location = `${redirect_url}/map`;
    }
  };
  const backToLogin = <ToggleReset onClick={() => history.push("/login")}>Back To Login</ToggleReset>;

  const formControll = <LoginTwoFactorForm onSubmit={handleTwoFactorAuthVerify} />;

  return (
    <Container>
      <LoginHeader />
      <Main className="main">
        {formControll}
        {backToLogin}
      </Main>
      <LoginFooter />
    </Container>
  );
}
