import {useEffect} from 'react';
import {createRadarChart} from '../../helpers/charts/RadarChart';
import {setRardarChart} from '../../store/charts';
import {useDispatch, useSelector} from 'react-redux';
import {addDataRadarChart} from '../../helpers/charts/updateRadarChart';
import {
  SelectionChartModal,
  ChartWrapper,
  ChartContainer,
  SelectionModalTitle,
  ChartTitle,
} from '../../styled/components/Charts/chart';
import {ClientsCreateForm} from '../Forms/ClientsCreateForm';

export const RadarChart = ({onCreateClients}) => {
  const clients = useSelector(state => state.clients.clients);
  const categories = useSelector(state => state.categories.categories);
  const chart = useSelector(state => state.charts.radarChart);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!categories.length || chart)
      return null;

    const radarChart = createRadarChart(categories);

    dispatch(setRardarChart(radarChart));
  }, [dispatch, categories, chart]);

  useEffect(() => addDataRadarChart(clients, categories),
      [clients, categories]);

  return (
      <ChartContainer>
        <SelectionChartModal visible={!clients.length}>
          <SelectionModalTitle>
            <ClientsCreateForm btnText={'Please upload your customer data'}
                               onCreateClients={onCreateClients}/>
          </SelectionModalTitle>
        </SelectionChartModal>
        <ChartTitle>
          Customers by value and Geodemographic breakdown
        </ChartTitle>
        <ChartWrapper>
          <canvas id="customer-value-chart"/>
        </ChartWrapper>
      </ChartContainer>
  );
};