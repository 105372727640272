import styled from 'styled-components';

export const Container = styled.div`
  display: ${({visible}) => visible ? 'block' : 'none'};
  position: absolute;
  z-index: 2;
  background-color: rgba(99, 106, 99, 0.8);
  width: 320px;
  left: 100px;
  top: 130px;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 240px;
  }
`;

export const Title = styled.h3`
  display: flex;
  justify-content: space-between;
  color: #fff;
  background-color: rgba(80, 80, 80, 0.7);
  font-size: 18px;
  font-weight: bold;
  padding: 10px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  & i {
    margin-left: 25px;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
`;

export const ChartTitle = styled.p`
  color: #fff;
  font-size: 18px;
  margin: 0 0 0 10px;
  padding: 0 10px;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

export const ModalPopup = styled.div`
  width: 100%;
  height: 360px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  z-index: 4;
  display: ${({visible}) => visible ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`;

export const ModalTitle = styled.h3`
  color: #E0E0E0;
  opacity: 1;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
`;