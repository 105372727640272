import {postcodesActionType} from './actions';

const initialState = {
  postcode: null,
};

export const postcodesReducer = (state = initialState, action) => {
  switch (action.type) {

    case postcodesActionType.GET_POSTCODE:
      return {...state, postcode: action.payload};

    default:
      return state;
  }
};