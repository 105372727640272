import {UserSearchForm} from '../Forms/UserSearchForm';
import {
    Container,
    Results,
    Select,
} from '../../styled/components/Controls/UserControl';
import {useDispatch} from 'react-redux';
import {getUsersAsync} from '../../store/users';
import {UserCreateForm} from '../Forms/UserCreateForm';

export function UserControl({onSearchUser, onCreateUser}) {
    const dispatch = useDispatch();

    const handleChangeResult = number => dispatch(getUsersAsync(1, number));

    return (
        <Container>
            <UserCreateForm onCreateUser={onCreateUser}/>
            <UserSearchForm onSearchUser={onSearchUser}/>
            <Results>
                Results
                <Select value={25} onChange={e => handleChangeResult(e.target.value)}>
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>25</option>
                </Select>
            </Results>
        </Container>
    );
}
