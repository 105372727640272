import {
  Route,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkSubDomainAsync } from '../store/profile';
import { message, Spin, Space } from 'antd';
export function LoginRoute({ children, ...rest }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const hostname = window.location.hostname;
      const domainParts = hostname.split('.');
      let subdomain = '';
      if (domainParts.length > 2) {
        subdomain = domainParts[0];
      }
      if (subdomain) {
        const response = await dispatch(checkSubDomainAsync(subdomain));
        if (response?.error) {
          message.error(response.error);
          window.location.href = `${window.location.protocol}//${domainParts.slice(-2).join('.')}`;
        }

      }

      setIsLoading(false);
    })();
  }, [dispatch]);

  return <Route
    {...rest}
    render={() => {

      return children;
    }
    }
  />;
}
