import styled from 'styled-components';

export const PopupWrapper = styled.div`
  width: 500px;
  position: absolute;
  right: 50px;
  top: 50px;
  background-color: rgba(99, 106, 99, 0.9);
  color: #fff;
`;

export const PopupTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  background-color: rgba(80, 80, 80, 0.7);
  & i {
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
`;

export const PopupDescription = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 0 10px 10px 10px;
  & p {
    margin-bottom: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  & thead td {
    font-weight: 700;
  }
  & tbody td {
    font-weight: 400;
  }
  & thead tr {
    border-bottom 1px solid #000;
  }
  & tr td:nth-child(1) {
    width: 100px;
  }
  & tr td:nth-child(2) {
    width: 200px;
    text-align: center;
  }
  & tr td:last-child {
    text-align: right;
  }
  & tr td:nth-child(3) {
    text-align: right;
    width: 100px;
  }
`;

export const Image = styled.img`
    width: 250px;
`;

export const BuildPeriodsImage = styled.img`
    width: 200px;
`;
