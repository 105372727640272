import Style from 'ol/style/Style';
import {Fill, Stroke, Text} from 'ol/style';

export const setFeatureStyle = (feature, size) => feature.setStyle(new Style({
  text: new Text({
    text: '',
    font: `900 ${size}px "Font Awesome 5 Free"`,
    fill: new Fill({
      color: feature.get('categoryColor'),
    }),
    stroke: new Stroke({
      color: 'rgba(0,0,0)',
      width: 3,
    }),
  }),
}));

export const updateFeaturesStyle = (map, client) => map.getLayers()
    .getArray()
    .forEach(layer => {
      if (layer.get('name') === 'VectorLayer') {
        const features = layer.getSource().getFeatures();

        features.forEach(feature => {
          if (!feature.get('categoryColor'))
            return null;

          if (!client)
            return setFeatureStyle(feature, 18);

          if (feature.get('name') === client.customer_id) {
            setFeatureStyle(feature, 30);
          } else {
            setFeatureStyle(feature, 18);
          }
        });
      }
    });