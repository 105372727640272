import {
  Page,
  ActivePage, Paginate,
} from '../../styled/components/Paginates/UsersPaginate';
import {useDispatch, useSelector} from 'react-redux';
import {getUsersAsync} from '../../store/users';

export function UsersPaginate() {
  const {pages,perPage,currentPage} = useSelector(state => state.users.pagination);
  const dispatch = useDispatch();

  const handleClickPage = (page) => dispatch(getUsersAsync(page,perPage));
  
  const pageControl = pages && pages.map(page => page === currentPage
      ? <ActivePage key={page} onClick={() => handleClickPage(page)}>{page}</ActivePage>
      : <Page key={page} onClick={() => handleClickPage(page)}>{page}</Page>);

  return <Paginate>Page {pageControl}</Paginate>;
}