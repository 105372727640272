import apiClient from '../../libs/apiClient';
import {message} from 'antd';

export const settingActionType = {
    GET_SETTING_TWO_FACTOR: 'GET_SETTING_TWO_FACTOR',
    GET_ADMIN_SETTING: 'GET_ADMIN_SETTING',
    SET_ADMIN_SETTING: 'SET_ADMIN_SETTING',
};

export const getTwoFactorAuthSettingAsync = () => async dispatch => {
    try {
        const {data: response} = await apiClient.get(
            `auth/2fa/settings`);

        dispatch({type: settingActionType.GET_SETTING_TWO_FACTOR, payload: response});
    } catch (e) {
        message.error('Internal server error.');
    }
};


export const getAdminSettingAsync = () => async dispatch => {
    try {
        const {data: response} = await apiClient.get(
            `settings`);
        dispatch({type: settingActionType.GET_ADMIN_SETTING, payload: response});
    } catch (e) {
        message.error('Internal server error.');
    }
};


export const setAdminSettingAsync = (setting) => async dispatch => {
    try {
        const {data: response} = await apiClient.post(`settings`, setting);
        dispatch({type: settingActionType.SET_ADMIN_SETTING, payload: response});
    } catch (error) {
        if (error.response.data.errors) {
            return Object.values(error.response.data.errors).forEach(err => message.error(err))
        } else if (error.response.data.exception) {
            return message.error(error.response.data.exception)
        }
        return message.error('Internal server error.');
    }
};

