import { useEffect, useState } from "react";
import { ModalForm, ModalFormWrapper } from "../../styled/components/Popups/HelpPopup";
import { PopupDescription, PopupTitle } from "../../styled/components/Popups/HelpPopup";
import { Table, Button, message } from "antd";
import apiClient from "../../libs/apiClient";
import { useHistory, useNavigate } from 'react-router-dom';
export const MySavedArea = ({ btnText }) => {
  const [visible, setVisible] = useState(false);
  const [savedAreas, setSavedAreas] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleVisible = () => setVisible(!visible);
  const history = useHistory();
  // Fetch saved areas data
  const fetchSavedAreas = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("/saved-areas");
      setSavedAreas(data);
    } catch (error) {
      setSavedAreas(null);
      message.error("Failed to load saved areas.");
    } finally {
      setLoading(false);
    }
  };

  // Load data when modal opens
  useEffect(() => {
    if (visible) fetchSavedAreas();
  }, [visible]);

  // Define table columns
  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            type="primary"
            onClick={() => handleExport(record)}
          >
            Export
          </Button>
          <Button
            type="default"
            onClick={() => handleViewOnMap(record)}
          >
            View on Map
          </Button>
        </div>
      ),
    },
  ];

  // Export button
  const handleExportX = (record) => {
    const link = document.createElement("a");
    link.href = `/api/saved-areas/${record.id}/export`;
    link.setAttribute("download", `saved_area_${record.id}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    message.success(`Exporting area: ${record.name}`);
  };
  const handleExport = async (record) => {
    try {
      const response = await apiClient.get(`/saved-areas/${record.id}/export`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `saved_area_${record.id}.csv`);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      message.success(`Exported area: ${record.name}`);
    } catch (error) {
      console.error("Export error:", error);
      message.error("Failed to export the area.");
    }
  };
  // View on Map 
  const handleViewOnMap = (areaRecord ) => {
    handleVisible();
    history.push("/map", { state: { areaRecord  } });
    //message.info(`Viewing ${area.name} on map`);
  };


  return (
    <>
      <ModalForm
        visible={visible}
        centered
        width={"1000px"}
        onCancel={handleVisible}
        footer={null}
        title={"My Saved Areas"}
      >
        <ModalFormWrapper>

          <PopupDescription>
            Here’s a list of your saved areas. You can export the data or view it directly on the map.
          </PopupDescription>

          <Table
            dataSource={savedAreas}
            columns={columns}
            rowKey="id"
            loading={loading}
            pagination={false}
          />
        </ModalFormWrapper>
      </ModalForm>

      <div onClick={handleVisible}>{btnText}</div>
    </>
  );
};
