import {useDispatch, useSelector} from 'react-redux';
import {
  CloseIcon,
  Container, TextPopup,
} from '../../styled/components/Popups/CustomerPopup';
import {useEffect} from 'react';
import {createCustomerChart} from '../../helpers/charts/customerPopupChart';
import {setCustomerPopupChart} from '../../store/charts';
import {setClientPopup} from '../../store/maps';
import {updateFeaturesStyle} from '../../helpers/features/updateFeature';
import {usePendingPayment} from "../../hooks/usePendingPayment";
import {setVisibleToPayPopup} from "../../store/profile";

export const CustomerPopupCard = () => {
  const clientPopup = useSelector(state => state.maps.clientPopup);
  const map = useSelector(state => state.maps.map);
  const customerPopupChart = useSelector(
      state => state.charts.customerPopupChart);
  const dispatch = useDispatch();
  const pendingPayment = usePendingPayment()

  useEffect(() => {
    if (!clientPopup.visible)
      return null;

    if (pendingPayment) {
        dispatch(setVisibleToPayPopup(true))
        handleClosePopup()
    }

    const chart = createCustomerChart();
    dispatch(setCustomerPopupChart(chart));
  }, [clientPopup.visible,dispatch]);

  useEffect(() => {
    if (!customerPopupChart)
      return null;

    customerPopupChart.data.datasets[0].data = clientPopup.orders;
    customerPopupChart.update();
  }, [customerPopupChart, clientPopup.orders]);

  const handleClosePopup = () => {
    dispatch(setClientPopup({visible: false}));
    updateFeaturesStyle(map);
  };

  if (!clientPopup.visible)
    return null;

  return (
      <Container>
        <div>
          <TextPopup>
            Customer ID: {clientPopup.clientId}
          </TextPopup>
          <TextPopup>
            {clientPopup.category.toUpperCase()}
          </TextPopup>
          <TextPopup>
            Total orders: &#163;{clientPopup.totalOrders}
          </TextPopup>
          <TextPopup>
            Average annual order value: &#163;{clientPopup.averageOrder}
          </TextPopup>
          <canvas className="customer-chart" id="customer-chart" width="200"
                  height="150"/>
        </div>
        <CloseIcon onClick={handleClosePopup}
                   className="fas fa-times popup__btn"/>
      </Container>
  );
};
