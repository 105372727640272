import { ModalForm, ModalFormWrapper } from '../../styled/components/Popups/HelpPopup';
import { useEffect, useState } from "react";
import { PopupDescription, PopupTitle } from "../../styled/components/Popups/HelpPopup";
import { TwoFactorAuth } from '../../components/Dashboards/TwoFactorAuth';
import { useParams } from 'react-router-dom';
export const SettingTwoFactorAuthForm = ({ btnText, autoOpen }) => {
  const [visible, setVisible] = useState(false)
  const { page } = useParams();
  const handleVisible = () => {
    setVisible(!visible)
  }
  useEffect(() => {
    if (autoOpen) {
      setVisible(true);
    }
    if (page === 'setting') {
      setVisible(true);
    } else {
      setVisible(false)
    }
  }, [autoOpen, page]);
  return (
    <>
      <ModalForm
        visible={visible}
        centered={true}
        width={'1000px'}
        closable={false}
        //onCancel={() => setVisible(!visible)}
        footer={null}
        title={'Two-Factor Authentication'}
      >
        <ModalFormWrapper>
          <TwoFactorAuth handleClose={() => setVisible(false)} />
        </ModalFormWrapper>
      </ModalForm>
      <div onClick={handleVisible}>
        {btnText}
      </div>
    </>
  );
};
