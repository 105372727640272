import {
  Header,
  Image,
  ImageDescription,
} from '../../styled/components/Pages/Login';

export function LoginHeader() {
  return (
      <Header>
          <Image src="/geoptimise-logo-blue.png" alt="Company's logo"/>
        <ImageDescription>Find more of your best customers.</ImageDescription>
      </Header>
  );
}