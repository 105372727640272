import {
    Row, RowAction,
} from '../../styled/components/Dashboards/AdminDashboard';
import classNames from 'classnames/bind';
import {MinusOutlined} from '@ant-design/icons';
import {ActionsDropdown} from '../Dropdowns/ActionsDropdown';
import moment from "moment";

export function User({user, onDeleteUser, onUpdateUser,onTwoFAUser}) {
    const statusClass = classNames({'#107100': user.status === 'active'},
        {'#ff0000': user.status === 'suspended'}, {'#F79237': user.status === 'pending'});

    const planControl = user.role === 'admin' ? <MinusOutlined/> : user.plan;

    const sessionTime = () => {
        const lastLogin = new Date(user.last_login).getTime()
        const lastLogout = new Date(user.last_logout).getTime()

        if (!lastLogin) return ''
        if (lastLogin > lastLogout) return 'online';

        return getNormalDuration(lastLogout - lastLogin);
    }

    const getNormalDuration = (duration) => {
        const days = moment.duration(duration).days()
        const hour = moment.duration(duration).hours()
        const minutes = moment.duration(duration).minutes()
        const seconds = moment.duration(duration).seconds()
        let result = ''
        if (days) {
            let label = days > 1 ? ' days ' : ' day '
            result += days + label
        }

        if (hour) {
            let label = hour > 1 ? ' hours ' : ' hour '
            result += hour + label
        }

        if (minutes) {
            let label = minutes > 1 ? ' minutes ' : ' minute '
            result += minutes + label
        }

        if (!result && seconds) {
            let label = seconds > 1 ? ' seconds ' : ' second '
            result += seconds + label
        }
        return result
    }

    return (
        <tr>
            <Row theme={{color: '#415e85'}}>{user.name}</Row>
            <Row theme={{color: '#415e85'}}>{user.company}</Row>
            <Row theme={{color: '#415e85'}}>{user.role}</Row>
            <Row theme={{color: statusClass}}>{user.status}</Row>
            <Row theme={{color: '#415e85'}}>{planControl}</Row>
            <Row theme={{color: '#415e85'}}>{user.email}</Row>
            <Row theme={{color: '#415e85'}}>{user.last_login}</Row>
            <Row theme={{color: '#415e85'}}>{sessionTime()}</Row>
            <RowAction theme={{color: '#415e85'}}>
                <ActionsDropdown role={user.role}
                                 user={user}
                                 onDelete={onDeleteUser}
                                 onUpdate={onUpdateUser}
                                 onTwoFA={onTwoFAUser}/>
            </RowAction>
        </tr>
    );
}
