import {store} from '../../store';
import {setClientPopup} from '../../store/maps';
import {toLonLat} from 'ol/proj';
import {filterCoordinate} from '../filters/coordinate';
import {updateFeaturesStyle} from '../features/updateFeature';

const getClientByCoordinates = (feature) => {
  const clients = store.getState().clients.clients;
  const clientPopup = store.getState().maps.clientPopup;
  const coordinates = feature.getGeometry().getCoordinates();

  clientPopup.overlay.setPosition(coordinates);

  const coordinateFeature = toLonLat(coordinates);
  return clients.find(client => filterCoordinate(client, coordinateFeature));
};

const setClientData = (client, feature) => {
  const clientOrders = Object.entries(client)
      .filter(([key]) => key.includes('year'))
      .map(([_, price]) => Number(price));

  const totalOrders = clientOrders.reduce((price, acc) => acc + price)
      .toFixed(0);
  const averageOrder = (totalOrders / clientOrders.length).toFixed(0);

  store.dispatch((setClientPopup(
      {
        category: feature.get('category'),
        clientId: client.customer_id,
        orders: clientOrders,
        totalOrders,
        averageOrder,
        visible: true,
      })));
};

export const createCustomerPopup = (customerId) => {
  const map = store.getState().maps.map;
  const clientPopup = store.getState().maps.clientPopup;

  if (!customerId) {
    updateFeaturesStyle(map);
    return clientPopup.overlay.setPosition(null);
  }

  map.getLayers().getArray().forEach(layer => {
    if (layer.get('name') !== 'VectorLayer')
      return null;

    layer.getSource().getFeatures().forEach(feature => {
      if (feature.get('name') !== customerId)
        return null;

      const client = getClientByCoordinates(feature);

      if (!client)
        return null;

      setClientData(client, feature);
      updateFeaturesStyle(map, client);
    });
  });
};
