import {
  Form,
  Label,
  Input,
  Button,
  TextError,
} from '../../styled/components/Pages/Login';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
export function LoginTwoFactorForm({ onSubmit }) {
  const location = useLocation();
  const { credentials } = location.state || {};
  const formik = useFormik({
    initialValues: {
      code: '',
      email: credentials ? credentials?.email : ''
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .matches(/^\d+$/, "Only numbers are allowed")
        .length(6, "Code must be exactly 6 digits")
        .required("Code is required"),
    }),
    onSubmit: async (values, formikHelpers) => {
      //onSubmit(values);
      formikHelpers.setSubmitting(true);
      try {
        const response = await onSubmit(values);

      } catch (error) {
        //console.error("API error:", error);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
  });

  const codeValidationError = formik.touched.code && formik.errors.code ? (
    <TextError>{formik.errors.code}</TextError>
  ) : null;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Label htmlFor="code">Two-Factor Authentication</Label>
      <Input

        className="textbox"
        id="auth-code"
        type="text"
        name="code"
        value={formik.values.code}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          let value = e.target.value.replace(/\D/g, "");
          if (value.length > 6) value = value.slice(0, 6);
          formik.setFieldValue("code", value);
        }}
        placeholder="Enter 2FA Code"
      />
      {codeValidationError}
      <Button type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Verifying..." : "Verify"}</Button>
    </Form>
  );
}
