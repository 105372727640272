import {
  Btn,
  Form, Input, Label,
  Line, WarningText,
} from '../../styled/components/Forms/UserDeleteForm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersByCompanyAsync } from '../../store/users';
import { CloseBtn, WrapperBtn } from '../../styled/components/Common/common';
import { Modal } from 'antd';

export function UserTwoFactorForm({ onTwoFA, user }) {
  const usersByCompany = useSelector(state => state.users.usersByCompany);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      visible: false,
    },
    onSubmit: (values, formikHelpers) => {
      onTwoFA(user);
      formikHelpers.resetForm();
    },
  });

  useEffect(() => {
    if (!user)
      return null;

    (async () => await dispatch(getUsersByCompanyAsync(user.company)))();
  }, [user, dispatch]);


  return (
    <>
      <Modal footer={false} closable={false} width={0}
        visible={formik.values.visible}>
        <Form onSubmit={formik.handleSubmit}>
          <Line />
          <WrapperBtn>
            <CloseBtn onClick={() => formik.setFieldValue('visible', false)} />
          </WrapperBtn>
          &nbsp;
          <Btn type="submit">Reset 2FA Authentication</Btn>
        </Form>
      </Modal>
      <div onClick={() => formik.setFieldValue('visible', true)}>Reset 2FA</div>
    </>
  );
}
