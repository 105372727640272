import {
 Container, Wrapper,
} from '../../styled/components/Dashboards/ClientDashboard';
import {CustomerSpendChart} from '../Charts/CustomerSpendChart';
import {CustomerGeoChart} from '../Charts/CustomerGeoChart';
import {RadarChart} from '../Charts/RadarChart';
import {SelectionGeoChart} from '../Charts/SelectionGeoChart';
import {useParams} from 'react-router-dom';

export const ClientDashboard = ({onClosePopups, onCreateClients}) => {
  const {page} = useParams();

  return (
      <Container visible={page === 'dashboard'}
                      className="chart-container">
        <Wrapper>
          <CustomerSpendChart onCreateClients={onCreateClients}
                              onClosePopups={onClosePopups}/>
          <CustomerGeoChart onCreateClients={onCreateClients}
                            onClosePopups={onClosePopups}/>
        </Wrapper>
        <Wrapper>
          <RadarChart onCreateClients={onCreateClients}/>
          <SelectionGeoChart onClosePopups={onClosePopups}/>
        </Wrapper>
      </Container>
  );
};