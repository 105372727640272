import styled from 'styled-components';
import {
  Button, Collapse, Upload, Input, Modal,
} from 'antd';
import {geoptimiseColor} from '../Variables/variables';

export const ModalForm = styled(Modal)`
  @media (max-width: 800px) {
    transform-origin: center;
    width: 250px!important;
  }

  & .ant-modal-content {
    width: 400px;
    border-top: 5px solid rgb(91, 160, 241);
    @media (max-width: 800px) {
      width: 250px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > span {
    width: 370px;

    @media (max-width: 800px) {
      width: 200px;
    }
  }
`;

export const Dragger = styled(Upload.Dragger)`
  padding: 10px;
`;

export const CollapseWrapper = styled(Collapse)`
  margin: 10px 0 0 0;
  width: 370px;

  @media (max-width: 800px) {
    width: 200px;
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 0 0;
`;

export const OptionItem = styled.label`
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export const OptionInput = styled(Input)`
  margin-left: 15px;
  width: 150px;
  padding: 5px;
  box-shadow: none;

  &:hover {
    border: 1px solid ${geoptimiseColor};
  }

  &:focus {
    border: 1px solid ${geoptimiseColor};
  }
`;

export const ExampleWrapper = styled.div`
  margin: 10px 0 0 0;
  width: 370px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 800px) {
    width: 200px;
    flex-direction: column;
  }
`;

export const ExampleText = styled.div`
  margin-right: 15px;
  font-size: 16px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const DownloadBtn = styled(Button)`
  margin: 10px 0;
  border: 1px solid ${geoptimiseColor};
  color: ${geoptimiseColor};

  @media (max-width: 800px) {
    font-size: 12px;
  }

  & i {
    margin-left: 10px;
    font-size: 16px;

    @media (max-width: 800px) {
      font-size: 12px;
    }
  }

  &:hover {
    background-color: ${geoptimiseColor};
    border: 1px solid ${geoptimiseColor};
    color: #fff;
  }
`;

