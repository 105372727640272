import {
  BtnControl,
  IconControl,
} from '../../styled/components/Controls/MapControl';
import {setZoom} from '../../store/maps';
import {useDispatch, useSelector} from 'react-redux';

export const IncreaseZoomBtn = () => {
  const map = useSelector(state => state.maps.map);
  const dispatch = useDispatch();

  const handleIncreaseZoom = () => {
    const view = map.getView();
    const zoom = view.getZoom();

    if (zoom === view.getMaxZoom())
      return null;

    dispatch(setZoom(zoom + 1));

    return view.setZoom(zoom + 1);
  };

  return (
      <BtnControl title='Zoom in' onClick={handleIncreaseZoom}>
        <IconControl className="fas fa-plus"/>
      </BtnControl>
  );
};
