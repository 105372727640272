import { BtnControl, IconControl } from '../../styled/components/Controls/MapControl';
import WKT from 'ol/format/WKT';
import { fromCircle } from 'ol/geom/Polygon';
import { getGroupsByCodeAsync } from '../../store/groups';
import { closePopup } from '../../helpers/popup/closePopup';
import Draw from 'ol/interaction/Draw';
import { setDraw } from '../../store/maps';
import { useDispatch, useSelector } from 'react-redux';
import { Circle, Polygon } from 'ol/geom';
import { usePendingPayment } from "../../hooks/usePendingPayment";
import { setVisibleToPayPopup } from "../../store/profile";
import { useLocation } from 'react-router-dom';
import { useEffect, useCallback } from 'react';
import Feature from 'ol/Feature';
import * as olProj from 'ol/proj';
import { setLoading } from "../../store/loading";
import { useHistory } from 'react-router-dom';
export const MapSelectionBtn = ({ children }) => {
  const map = useSelector(state => state.maps.map);
  const profile = useSelector(state => state.profile.profile);
  const source = useSelector(state => state.maps.source);
  const dispatch = useDispatch();
  const pendingPayment = usePendingPayment();
  const location = useLocation();
  const locationState = location.state?.state || {};
  const history = useHistory();

  // Clear existing drawn features (avoid removing initialized features)
  const clearExistingFeatures = useCallback(() => {
    source.getFeatures().forEach(feature => {
      const geometry = feature.getGeometry();
      if (geometry instanceof Polygon || geometry instanceof Circle) {
        source.removeFeature(feature);
      }
    });
  }, [source]);

  // Handle draw end event
  const handleDrawEnd = useCallback(
    (event) => {
      const drawGeom = event.feature.getGeometry();
      const format = new WKT();
      const geometryPolygon = drawGeom instanceof Polygon ? drawGeom : fromCircle(drawGeom);
      const polygonWKT = format.writeGeometry(
        geometryPolygon.clone().transform('EPSG:3857', 'EPSG:4326')
      );

      map.getLayers().getArray().forEach(layer => {
        if (layer.get('name') === 'Geodemographics' && layer.get('visible')) {
          dispatch(getGroupsByCodeAsync(polygonWKT));
        }
      });
    },
    [dispatch, map]
  );

  // Initialize drawing interaction
  const initializeDrawInteraction = useCallback(() => {
    closePopup();
    const drawType = profile.plan === 'ultimate' ? 'Polygon' : 'Circle';

    const draw = new Draw({
      source: source,
      type: drawType,
    });

    dispatch(setDraw(draw));
    draw.on('drawstart', clearExistingFeatures);
    draw.on('drawend', handleDrawEnd);

    map.addInteraction(draw);
  }, [profile.plan, source, dispatch, map, clearExistingFeatures, handleDrawEnd]);

  // Auto-render saved polygon on load if areaRecord is available
  useEffect(() => {
    const { areaRecord } = locationState || {};
    if (areaRecord?.id && !pendingPayment && areaRecord?.polygon_data?.type === 'Polygon') {
      if (map && source) {
        dispatch(setLoading(true));
        clearExistingFeatures();
        initializeDrawInteraction();

        const coordinates = areaRecord.polygon_data.coordinates[0].map(coord =>
          olProj.fromLonLat([coord[0], coord[1]])
        );

        const polygon = new Polygon([coordinates]);
        const feature = new Feature({ geometry: polygon });

        source.addFeature(feature);
        map.getView().fit(polygon.getExtent(), { padding: [20, 20, 20, 20] });
        map.render();
        history.push("/map", { state: {} });
      }
    }
  }, [locationState, map, pendingPayment, source, clearExistingFeatures, initializeDrawInteraction]);


  const handleButtonClick = () => {
    if (pendingPayment) {
      dispatch(setVisibleToPayPopup(true));
    } else {
      initializeDrawInteraction();
    }
  };

  const contentControl = children || <IconControl className="fas fa-search-plus" />;

  return (
    <BtnControl title="Map Selection" onClick={handleButtonClick}>
      {contentControl}
    </BtnControl>
  );
};
