import { Container } from '../styled/components/Pages/Admin';
import { AdminHeader } from '../components/Headers/AdminHeader';
import { AdminSetting } from '../components/Dashboards/AdminSetting';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUserAsync } from '../store/profile';
import { useEffect } from 'react';

export function Setting() {
  const dispatch = useDispatch();
  const handleLogoutUser = () => dispatch(logoutUserAsync());

  return (
    <Container>
      <AdminHeader onLogout={handleLogoutUser} />
      <AdminSetting />
    </Container>
  );
}
