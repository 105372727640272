import {
  Form, Modal,
  HelpDescription,
  Logo, Wrapper,
  CheckboxPopup, PopupLink,
} from '../../styled/components/Forms/HelpGuideForm';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateWelcomePopupAsync} from '../../store/profile';

export const WelcomePopup = () => {
  const profile = useSelector(state => state.profile.profile);
  const [visible, setVisible] = useState(profile.welcome_popup);
  const [isCheck, setIsCheck] = useState(false);
  const dispatch = useDispatch();

  const handleChangeCheckbox = () => setIsCheck(!isCheck);

  const handleShowWelcomePopup = () => {
    dispatch(updateWelcomePopupAsync(profile.id, {welcome_popup: !isCheck}));
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      width={600}
      failed_jobs centered={true}
      onCancel={() => setVisible(false)}>
      <Form>
        <Logo
          src="/geoptimise-logo-blue.png"
          alt="Logo company"
        />
        <HelpDescription>
          Welcome to Geoptimise! A tool to help you rapidly view and profile
          your customers
          on a map. It helps you identify your best (and worst) customers, so
          you can Geoptimise your customer base. Navigate the map using the
          icons
          on the left or to get started upload your own data using the
          'Upload' button at the top right.
        </HelpDescription>
        <CheckboxPopup
          checked={isCheck}
          onChange={handleChangeCheckbox}>
          Don't show this again
        </CheckboxPopup>
        <Wrapper>
          <PopupLink
            to={'/dashboard'}
            onClick={handleShowWelcomePopup}
          >
            Go to Dashboard
          </PopupLink>
          <PopupLink
            to={'/map'}
            onClick={handleShowWelcomePopup}
          >
            Go to Map
          </PopupLink>
        </Wrapper>
      </Form>
    </Modal>
  );
};
