import {Container} from '../styled/components/Pages/Admin';
import {AdminHeader} from '../components/Headers/AdminHeader';
import {AdminDashboard} from '../components/Dashboards/AdminDashboard';
import {useDispatch, useSelector} from 'react-redux';
import {
    deleteUserAsync,
    getPlansAsync,
    getStatusesAsync,
    getUsersAsync,
    setUserAsync,
    updateUserAsync,
    resetTwoFactorAuthUserAsync
} from '../store/users';
import {logoutUserAsync} from '../store/profile';
import {useEffect} from 'react';

export function Admin() {
  const page = useSelector(state => state.users.pagination.currentPage);
  const limit = useSelector(state => state.users.pagination.perPage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersAsync());
    dispatch(getPlansAsync());
    dispatch(getStatusesAsync());
  }, [dispatch]);

  const handleCreateUser = user => dispatch(setUserAsync(user));

  const handleDeleteUser = (email) => dispatch(
      deleteUserAsync(email, page, limit));

  const handleSearchUser = (name) => dispatch(
      getUsersAsync(page, limit, name));

  const handleUpdateUser = (user, updateUser) => dispatch(
      updateUserAsync(user, updateUser));

  const handleLogoutUser = () => dispatch(logoutUserAsync());

  const handleTwoFactorAuthUser = (user) => dispatch(
    resetTwoFactorAuthUserAsync(user));
    
  return (
      <Container>
        <AdminHeader onLogout={handleLogoutUser}/>
        <AdminDashboard
            onSearchUser={handleSearchUser}
            onUpdateUser={handleUpdateUser}
            onDeleteUser={handleDeleteUser}
            onCreateUser={handleCreateUser}
            onTwoFAUser={handleTwoFactorAuthUser}
        />
      </Container>
  );
}
